import { createApi } from '@reduxjs/toolkit/query/react';
import { LoginInput } from '../../layouts/admin/login/LoginLayout';
import customFetchBase from './customFetchBase';
import { GenericResponse, IResetPasswordRequest } from './types';
import { userApi } from './userApi';
import { setUser } from "../features/userSlice";
import { IUser } from "./types";

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    loginUser: builder.mutation<
      { token:string },
      LoginInput
    >({
      query(data) {
        return {
          url: 'admin/login',
          method: 'POST',
          body: data,
          credentials: 'same-origin',
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          localStorage.setItem('token', data.token);
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (err) {
          console.log("error... ", err);
        }
        
        /*try {
          await queryFulfilled;
          await dispatch(userApi.endpoints.getMe.initiate(null));
        } catch (error) {}*/
      },
    }),
  }),
});

export const {
  useLoginUserMutation,
} = authApi;
