const translations = {
  en: {
    translation: {
      Login: "Login",
      Passwordfor: "Forgot Password",
      Copyright: "Copyright",
      Pass: "Password",
      Emailaddress: "Email Address",
      Singin: "Sing In",
      Logout: "Log Out",
      Passwordrecovery:"Password Recovery",
      EnterEmail: "Enter your Email",
      Dashboard: "Dashboard",
      Users: "Users",
      Continue: "Continue",
      Save: "Save",
      Cancel: "Cancel",
      UserList: "User List",
    },
  },
  es: {
    translation: {
      Login: "Iniciar sesión",
      Passwordfor: "¿Olvidaste tu contraseña?",
      Copyright: "Derechos de Autor",
      Pass: "Contraseña",
      Emailaddress: "Correo Electronico",
      Singin: "Iniciar Secion ",
      Logout: "Cerrar Sesion",
      Passwordrecovery:"Recupera tu contraseña",
      EnterEmail: "Ingresa tu Correo Electronico",
      Dashboard: "Panel de control",
      Users: "Usuarios",
      Continue: "Continuar",
      Save: "Guardar",
      Cancel: "Cancelar",
      UserList: "Lista de Usuarios",
    },
  },
};

export default translations;
