import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { FC } from "react";
import FormInput from "../../../../components/ui/FormInput";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  
};

interface PropsStyle {
  open: any;
  handleOpen: any;
  handleClose: any;
}

const BasicModal: FC<PropsStyle> = ({ open, handleOpen, handleClose }) => {
  return (
    <Modal
    
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box  sx={style} alignContent="center">
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 1, mb:-2, marginLeft:8 }}>
          Password Recovery
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 3, mb: 1, marginLeft:11 }}>
          Enter your Email
        </Typography>

        
      </Box>
    </Modal>
  );
};

export default BasicModal;
