import React, { useEffect, useMemo, useState, useCallback } from "react";
import MaterialReactTable, {
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  type MaterialReactTableProps,
  type MRT_Row,
  type MRT_Cell,
} from "material-react-table";
import styled from "@emotion/styled";
import {
  Box,
  Typography,
  Button,
  Toolbar,
  Grid,
  Paper,
  Tooltip,
  IconButton,
  DialogContent,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
  Switch
} from "@mui/material";
import DashboardNavbar from "../../../../components/ui/DashboardNavbar";
import { Delete, Edit, Phone } from "@mui/icons-material";
import { ServiceApiResponse, Service } from "../../../../models/ServiceApiResponse";
import { useGetAllServiceQuery, useCreateServiceMutation, useDeleteServiceMutation, useEditServiceMutation  } from "../../../../rx/api/ServiceApi";
import { useSnackbar } from "notistack";
import { NewAccountServiceModal } from "../../../../components/modals/NewAccountServiceModal";

const BoxMain = styled(Box)(({ theme }) => {
  return {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: "30px",
  };
});

const validatePhone = (phone: string) =>
  !!phone.length &&
  phone
    .toLowerCase()
    .match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/);
const validateRequired = (value: string) => !!value.length;
const validateEmail = (email: string) =>
  !!email.length &&
  email
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
const validateAge = (age: number) => age >= 18 && age <= 50;

const ServiceTablepage = () => {
  //data and fetching state
 const handleCreateNewRow = (values: Service) => {
    //tableData.push(values);
    //setTableData([...tableData]);
    createService({Name:values.Name})
  }; 
  const [data, setData] = useState<Service[]>([]);
  const [createService, {isLoading:isLoadingCreateService,isError:isErrorCreateService,error:errorCreateService,isSuccess:isSuccessCreateService}] = useCreateServiceMutation();
  const [deleteService, {isLoading:isLoadingDeleteService,isError:isErrorDeleteService,error:errorDeleteService,isSuccess:isSuccessDeleteService}] = useDeleteServiceMutation();
  const [editService, {isLoading:isLoadingEditService,isError:isErrorEditService,error:errorEditService,isSuccess:isSuccessEditService}] = useEditServiceMutation();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState<Service[]>(() => data);
  const [validationErrors, setValidationErrors] = useState<{
    [cellId: string]: string;
  }>({});

  const { enqueueSnackbar } = useSnackbar();

  const getCommonEditTextFieldProps = useCallback(
    (
      cell: MRT_Cell<Service>
    ): MRT_ColumnDef<Service>["muiTableBodyCellEditTextFieldProps"] => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid =
            cell.column.id === "email"
              ? validateEmail(event.target.value)
              : cell.column.id === "age"
              ? validateAge(+event.target.value)
              : cell.column.id === "phoneNumber"
              ? validatePhone(event.target.value)
              : validateRequired(event.target.value);
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors]
  );

  const handleSaveRowEdits: MaterialReactTableProps<Service>["onEditingRowSave"] =
    async ({ exitEditingMode, row, values }) => {
      if (!Object.keys(validationErrors).length) {
        //data[row.index] = values;
        //send/receive api updates here, then refetch or update local table data for re-render
        //setData([...data]);
        const data = {data:{Name:values.Name}, id:values.id}
        editService(data)
        exitEditingMode(); //required to exit editing mode and close modal
      }
    };

  const handleCancelRowEdits = () => {
    //setValidationErrors({});
  };

  const handleDeleteRow = useCallback(
    (row: MRT_Row<Service>) => {
      if (
        !window.confirm(
          `Are you sure you want to delete ${row.getValue("firstName")}`
        )
      ) {
        return;
      }
      //send api delete request here, then refetch or update local table data for re-render
      //data.splice(row.index, 1);
      //setData([...data]);
      deleteService(row.getValue("id"));
    },
    [data]
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  //table state
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const { isLoading: isLoadingList, isError: isErrorList, error, data: DataList, refetch, isFetching } = useGetAllServiceQuery(
    {
      start: pagination.pageIndex * pagination.pageSize ,
      size: pagination.pageSize, 
      filters: (JSON.stringify(columnFilters ?? [])), 
      globalFilter: globalFilter ?? "", 
      sorting: (JSON.stringify(sorting ?? []))
  });

  //if you want to avoid useEffect, look at the React Query example instead
  useEffect(() => {
    refetch();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    data
  ]);

  useEffect(() => {
    setIsLoading(isFetching)
  }, [
    isFetching
  ]);

  useEffect(() => {
    if (DataList) {
      setData(DataList.data);
      setRowCount(DataList.meta.totalRowCount);
    }
    setIsError(isErrorList)
    if(isErrorList && error != undefined){
      if((error as any).error !== undefined){
        enqueueSnackbar((error as any).error, {
          variant: "error",
        });
      }
    }
    

  }, [
    DataList,
    isErrorList,
    error,
    isLoadingList
  ]);

  useEffect(() => {
    if (isSuccessCreateService ) {
      enqueueSnackbar('Se Creo Correctamente', {
        variant: "success",
      });
      refetch();
    }
    if(isErrorCreateService && errorCreateService != undefined){
      if((errorCreateService as any).error !== undefined){
        enqueueSnackbar((errorCreateService as any).error, {
          variant: "error",
        });
      }
    }
  }, [
   
    isLoadingCreateService
  ]);

  useEffect(() => {
    if (isSuccessDeleteService ) {
      enqueueSnackbar('Se Elimino Correctamente', {
        variant: "success",
      });
      refetch();
    }
    if(isErrorDeleteService && errorDeleteService != undefined){
      if((errorDeleteService as any).error !== undefined){
        enqueueSnackbar((errorDeleteService as any).error, {
          variant: "error",
        });
      }
    }
  }, [
   
    isLoadingDeleteService
  ]);

  useEffect(() => {
    if (isSuccessEditService ) {
      enqueueSnackbar('Se Edito Correctamente', {
        variant: "success",
      });
      refetch();
    }
    if(isErrorEditService && errorEditService != undefined){
      if((errorEditService as any).error !== undefined){
        enqueueSnackbar((errorEditService as any).error, {
          variant: "error",
        });
      }
    }
  }, [
   
    isLoadingEditService
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
  };


  const columns = useMemo<MRT_ColumnDef<Service>[]>(
    () => [

      {
        accessorKey: "id", enableEditing:false, enableHiding:false, hidden:true,
        header: "ID",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
        
      },

      {
        accessorKey: "Name",
        header: "Name",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "created_at",enableEditing:false,
        header: "Created At",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "updated_at",enableEditing:false,
        header: "Update At",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "Active",enableEditing:false,
        header: "Active",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
          type: "tel",
        }),
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
            }}
          >
            <Switch checked={renderedCellValue == 1 ? true : false} onChange={handleChange}></Switch>
          </Box>
        ),
      },
    ],
    [getCommonEditTextFieldProps]
  );
console.log(DataList)
  const columnsnewdata = useMemo<MRT_ColumnDef<Service>[]>(
    () => [
      {
        accessorKey: "Name",
        header: "Name",
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
    ],
    [getCommonEditTextFieldProps]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardNavbar name={"Services"} />
      <BoxMain component="main">
        <Toolbar />
        <Box>
          <Typography style={{ color: "black" }}>
            <MaterialReactTable
              columns={columns}
              data={data}
              editingMode="modal" //default
              positionActionsColumn="last"
              enableColumnOrdering
              enableEditing
              onEditingRowSave={handleSaveRowEdits}
              onEditingRowCancel={handleCancelRowEdits}
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Tooltip arrow placement="left" title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="right" title="Delete">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteRow(row)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              enableRowSelection
              getRowId={(row) => row.id}
              initialState={{ showColumnFilters: false, columnVisibility:{id:false}}}
              manualFiltering
              manualPagination
              manualSorting
              muiToolbarAlertBannerProps={
                isError
                  ? {
                      color: "error",
                      children: "Error loading data",
                    }
                  : undefined
              }
              onColumnFiltersChange={setColumnFilters}
              onGlobalFilterChange={setGlobalFilter}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              rowCount={rowCount}
              state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
              }}
              renderTopToolbarCustomActions={() => (
                <Button
                  color="secondary"
                  onClick={() => setCreateModalOpen(true)}
                  variant="contained"
                >
                 Add New Service
                </Button>
              )}
            />
            <NewAccountServiceModal
              columns={columnsnewdata}
              open={createModalOpen}
              onClose={() => setCreateModalOpen(false)}
              onSubmit={handleCreateNewRow}
            />
          </Typography>
          
        </Box>
      </BoxMain>
    </Box>
  );
};

export default ServiceTablepage;
