import React, { useState, useEffect } from "react";
import styled from '@emotion/styled';
import { CircularProgress, useMediaQuery } from "@mui/material";
import LoginComponent from "./page/LoginPage"
import { object, string, TypeOf } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLoginUserMutation } from '../../../rx/api/authApi';
import { useSnackbar } from "notistack";
import { useAppSelector } from '../../../rx/store/store';
import { useNavigate } from 'react-router-dom';

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const loginSchema = object({
  Email: string()
    .min(1, 'Email address is required')
    .email('Email Address is invalid'),
  Password: string()
    .min(1, 'Password is required')
    .min(4, 'Password must be more than 4 characters')
    .max(32, 'Password must be less than 32 characters'),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const methods = useForm<LoginInput>({
    resolver: zodResolver(loginSchema),
  });

  const [loginUser, { isLoading, isError, error, isSuccess }] =
    useLoginUserMutation();


    const {
      reset,
      handleSubmit,
      formState: { isSubmitSuccessful },
    } = methods;

  useEffect(() => {
      if (isSuccess) {
        //toast.success('You successfully logged in');
        enqueueSnackbar('You successfully logged in', {
          variant: "success",
        });
        
        navigate("/admin/");
      }
      if (isError) {
        console.log(error);
        
        if (Array.isArray((error as any).data.error)) {
          (error as any).data.error.forEach((el: any) =>
            enqueueSnackbar(el.message, {
              variant: "warning",
            })
          );
        } else {
          enqueueSnackbar((error as any).data.message, {
            variant: "warning",
          })
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);


  useEffect(() => {
    if (isSubmitSuccessful) {
      //reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitSuccessful]);

  const onSubmitHandler: SubmitHandler<LoginInput> = (values) => {
    // 👇 Executing the loginUser Mutation
    loginUser(values);
  };
  
  return (
    <>
      {
        <LoginComponent 
        onSubmitHandler={onSubmitHandler} 
        handleSubmit={handleSubmit} 
        methods={methods}
        />
      }
    </>
  )
}

export default Login;