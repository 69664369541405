import * as React from 'react';
import Link from '@mui/material/Link';
import { FC } from "react";
import styled from '@emotion/styled';
import {
  Button,
  Typography,
  Box,
} from "@mui/material";
import BtnBlue from '../../../../components/buttons/BtnBlue';

interface PropsStyle {
  total: string;
  iconB: any;
}

function preventDefault(event: any) {
  event.preventDefault();
}

const Deposits: FC<PropsStyle> = ({ total, iconB }) => {

  return (<>
      <Typography component="h2" variant="h6" color="" gutterBottom>
        Recent Deposits
      </Typography>
      <Typography component="p" variant="h4">
        ${total}
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on {new Date().getDate() }-{new Date().getMonth() +1 }-{new Date().getFullYear() }
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>

       <BtnBlue variant="outlined" name= "Text 1"></BtnBlue>
       <BtnBlue variant="outlined" name= "Text 2"></BtnBlue>
       {iconB}

  </>);
}

export default Deposits;