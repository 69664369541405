import React from "react";
import styled from "@emotion/styled";
import { Box, Typography, Toolbar, Grid, Paper } from "@mui/material";
import DashboardNavbar from "../../../../components/ui/DashboardNavbar";
import { useAppSelector } from "../../../../rx/store/store";
import BtnBlue from "../../../../components/buttons/BtnBlue";
import Deposits from "../../example/components/Deposits";
import Cards from "../../example/components/Dashboarcards";

import PeopleIcon from "@mui/icons-material/People";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import theme from "../../../../theme";
import BookingCcards from "./BookingCcards";

const BoxMain = styled(Box)(({ theme }) => {
  return {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: "30px",
  };
});

const BookingCpage = ({}) => {
  const user = useAppSelector((state) => state.userState.user);
  console.log(user);

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardNavbar name={"Dashboard"} />
      <BoxMain component="main">
        <Toolbar />
        <Typography style={{ color: "black" }}>
          Hello {user?.Name} {user?.LastName}{" "}
        </Typography>

        <Grid sx={{ mt: 5, mb: 10 }}>
          <Grid container spacing={3}>
            <Cards
              namecard="Total Users"
              total={"300"}
              iconB={
                <PeopleIcon
                  style={{
                    height: "50px",
                    width: "50px",
                    color: theme.global.WhiteIce,
                  }}
                />
              }
              Bckcolor={theme.global.DarkBlue}
              lettersColor={theme.global.White}
            />
            <Cards
              namecard="Total Customers"
              total={"125"}
              iconB={
                <AccountBoxIcon
                  style={{
                    height: "50px",
                    width: "50px",
                    color: theme.global.WhiteIce,
                  }}
                />
              }
              Bckcolor={theme.global.DarkBlue}
              lettersColor={theme.global.White}
            />
            <Cards
              namecard="Total Taskers"
              total={"175"}
              iconB={
                <AssignmentIndIcon
                  style={{
                    height: "50px",
                    width: "50px",
                    color: theme.global.WhiteIce,
                  }}
                />
              }
              Bckcolor={theme.global.DarkBlue}
              lettersColor={theme.global.White}
            />
            <Cards
              namecard="Total Booking"
              total={"3000"}
              iconB={
                <LibraryBooksIcon
                  style={{
                    height: "50px",
                    width: "50px",
                    color: theme.global.WhiteIce,
                  }}
                />
              }
              Bckcolor={theme.global.DarkBlue}
              lettersColor={theme.global.White}
            />
            <Cards
              namecard="Today Booking"
              total={"25"}
              iconB={
                <LocalLibraryIcon
                  style={{
                    height: "50px",
                    width: "50px",
                    color: theme.global.WhiteIce,
                  }}
                />
              }
              Bckcolor={theme.global.DarkBlue}
              lettersColor={theme.global.White}
            />
            <Cards
              namecard="Total Shop"
              total={"3000"}
              iconB={
                <ShoppingCartIcon
                  style={{
                    height: "50px",
                    width: "50px",
                    color: theme.global.WhiteIce,
                  }}
                />
              }
              Bckcolor={theme.global.DarkBlue}
              lettersColor={theme.global.White}
            />
          </Grid>
        </Grid>
      </BoxMain>
    </Box>
  );
};

export default BookingCpage;
