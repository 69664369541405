import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import ContractorsTablepage from "./page/contractorsTablepage";
import { useLocation } from "react-router-dom";

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const ContractorsTable = () => {
  const location = useLocation();

  return <>{<ContractorsTablepage />}</>;
};

export default ContractorsTable;
