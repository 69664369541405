function StringToBoolean(value: any){  
  const truthy: string[] = [
      'true',
      'True',
      '1'
  ]

  return truthy.includes(value)
}

export default class BooleanConverts {
  static StringToBoolean(value: any) { return StringToBoolean(value); }
}