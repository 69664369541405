import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from '../features/userSlice';
import { authApi } from '../api/authApi';
import { userApi } from '../api/userApi';
import { userListApi } from '../api/userListApi';
import { questionApi } from '../api/QuestionApi';
import { adminApi } from '../api/AdminApi';
import { serviceApi } from '../api/ServiceApi';
import { contractorApi } from '../api/ContractorApi';
import { ClientListApi } from '../api/ClientApi';
export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [userListApi.reducerPath]: userListApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [serviceApi.reducerPath]: serviceApi.reducer,
    [ClientListApi.reducerPath]:ClientListApi.reducer,
    [contractorApi.reducerPath]: contractorApi.reducer,
    userState: userReducer,

  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      userListApi.middleware,
      questionApi.middleware,
      adminApi.middleware,
      serviceApi.middleware,
      contractorApi.middleware,
      ClientListApi.middleware,
    ]),

})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;