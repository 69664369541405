import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { QuestionApiResponse } from '../../models/QuestionApiResponse';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

export const questionApi = createApi({
  reducerPath: 'questionApi',
  baseQuery: customFetchBase,
  tagTypes: ['question'],
  endpoints: (builder) => ({
    getAllQuestion: builder.query<QuestionApiResponse, {start: any, size: any, filters: any, globalFilter: any, sorting: any}>({
      query({start, size, filters, globalFilter, sorting}) {
        return {
          url: `/admin/question/${start}/${size}/${globalFilter}`,
        };
      },
    }),
  }),
});

export const {
  useGetAllQuestionQuery,
} = questionApi;
