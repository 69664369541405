
import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { ServiceApiResponse } from '../../models/ServiceApiResponse';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: customFetchBase,
  tagTypes: ['service'],
  endpoints: (builder) => ({
    getAllService: builder.query<ServiceApiResponse, {start: any, size: any, filters: any, globalFilter: any, sorting: any}>({
      query({start, size, filters, globalFilter, sorting}) {
        return {
          url: `/admin/service/${start}/${size}/${globalFilter}`,
        };
      },
    }),
    createService: builder.mutation<{status:boolean}, {Name:any}>({
      query(post) {
        return {
          url: `/admin/service`, method:'POST', body: post,
        };
      },
    }),

    deleteService: builder.mutation<{status:boolean},string>({
      query(id) {
        return {
          url: `/admin/service/` + id, method:'DELETE',
        };
      },
    }),

    editService: builder.mutation<{status:boolean}, {data:any,id:any}>({
      query(put) {
        return {
          url: `/admin/service/`+ put.id, method:'PUT', body: put.data,
        };
      },
    }),




  }),
});

export const {
  useGetAllServiceQuery,useCreateServiceMutation, useDeleteServiceMutation, useEditServiceMutation
} = serviceApi;
