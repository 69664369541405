import {
  FormHelperText,
  Typography,
  FormControl,
  Input as _Input,
  InputProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

const Input = styled(_Input)(({ theme }) => {
  return {
    border: "1px solid #c2cad1 !important",
    background: "#ededed",
    "border-radius": "8px",
    padding: "0.4rem 0.7rem",
    fontFamily: "Fredoka One"
  };
});

type IFormInputProps = {
  name: string;
  label: string;
} & InputProps;

const FormInput: FC<IFormInputProps> = ({ name, label, ...otherProps }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Controller
      control={control}
      defaultValue=""
      name={name}
      render={({ field }) => (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <Typography
            variant="body2"
            sx={{ color: "#2363eb", mb: 1, fontWeight: 500 }}
          >
          </Typography>
          <Input
            {...field}
            fullWidth
            disableUnderline
            error={!!errors[name]}
            {...otherProps}
            placeholder={label}
          />
          <FormHelperText error={!!errors[name]}>
            {errors[name] ? (errors[name]?.message as unknown as string) : ""}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
};

export default FormInput;
