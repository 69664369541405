import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import ExapleNewPageComponent from "./page/ExapleNewPage";
import { useLocation } from "react-router-dom";

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const Example = () => {
  const location = useLocation();

  return <>{<ExapleNewPageComponent />}</>;
};

export default Example;
