import { createTheme } from '@mui/material/styles';
import { Theme, ThemeOptions } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  global: {
    danger: '#4c4f56',
    Abbey:'#4c4f56',
    AbsoluteZero:'#0048ba',
    Acadia:'#1b1404',
    Acapulco:'#7cb0a1',
    AcidGreen:'#b0bf1a',
    Aero:'#7cb9e8',
    AeroBlue:'#c9ffe5',
    Affair:'#714693',
    AfricanViolet:'#b284be',
    AirForceBlue:'#00308f',
    AirSuperiorityBlue:'#72a0c1',
    Akaroa:'#d4c4a8',
    AlabamaCrimson:'#af002a',
    Alabaster:'#fafafa',
    AlbescentWhite:'#f5e9d3',
    AlgaeGreen:'#93dfb8',
    AliceBlue:'#f0f8ff',
    AlienArmpit:'#84de02',
    AlizarinCrimson:'#e32636',
    AlloyOrange:'#c46210',
    Allports:'#0076a3',
    Almond:'#efdecd',
    AlmondFrost:'#907b71',
    Alpine:'#af8f2c',
    Alto:'#dbdbdb',
    Aluminium:'#a9acb6',
    Amaranth:'#e52b50',
    AmaranthPink:'#f19cbb',
    AmaranthPurple:'#ab274f',
    AmaranthRed:'#d3212d',
    Amazon:'#3b7a57',
    Amber:'#ffbf00',
    AmericanRose:'#ff033e',
    Americano:'#87756e',
    Amethyst:'#9966cc',
    AmethystSmoke:'#a397b4',
    Amour:'#f9eaf3',
    Amulet:'#7b9f80',
    Anakiwa:'#9de5ff',
    AndroidGreen:'#a4c639',
    AntiFlashWhite:'#f2f3f4',
    AntiqueBrass:'#cd9575',
    AntiqueBronze:'#665d1e',
    AntiqueFuchsia:'#915c83',
    AntiqueRuby:'#841b2d',
    AntiqueWhite:'#faebd7',
    Anzac:'#e0b646',
    Ao:'#008000',
    Apache:'#dfbe6f',
    Apple:'#4fa83d',
    AppleBlossom:'#af4d43',
    AppleGreen:'#8db600',
    Apricot:'#fbceb1',
    ApricotWhite:'#fffeec',
    AquaDeep:'#014b43',
    AquaForest:'#5fa777',
    AquaHaze:'#edf5f5',
    AquaIsland:'#a1dad7',
    AquaSpring:'#eaf9f5',
    AquaSqueeze:'#e8f5f2',
    Aquamarine:'#7fffd4',
    AquamarineBlue:'#71d9e2',
    Arapawa:'#110c6c',
    ArcticLime:'#d0ff14',
    Armadillo:'#433e37',
    ArmyGreen:'#4b5320',
    Arrowtown:'#948771',
    Arsenic:'#3b444b',
    Artichoke:'#8f9779',
    ArylideYellow:'#e9d66b',
    Ash:'#c6c3b5',
    AshGrey:'#b2beb5',
    Asparagus:'#87a96b',
    Asphalt:'#130a06',
    Astra:'#faeab9',
    Astral:'#327da0',
    Astronaut:'#283a77',
    AstronautBlue:'#013e62',
    AthensGray:'#eef0f3',
    AthsSpecial:'#ecebce',
    Atlantis:'#97cd2d',
    Atoll:'#0a6f75',
    AuChico:'#97605d',
    Aubergine:'#3b0910',
    Auburn:'#a52a2a',
    Aureolin:'#fdee00',
    AuroMetalSaurus:'#6e7f80',
    AustralianMint:'#f5ffbe',
    Avocado:'#568203',
    Axolotl:'#4e6649',
    Azalea:'#f7c8da',
    Aztec:'#0d1c19',
    AztecGold:'#c39953',
    Azure:'#007fff',
    AzureMist:'#f0ffff',
    AzureishWhite:'#dbe9f4',
    BabyBlue:'#89cff0',
    BabyBlueEyes:'#a1caf1',
    BabyPowder:'#fefefa',
    BahamaBlue:'#026395',
    Bahia:'#a5cb0c',
    BajaWhite:'#fff8d1',
    BakerMillerPink:'#ff91af',
    BaliHai:'#859faf',
    BallBlue:'#21abcd',
    BalticSea:'#2a2630',
    Bamboo:'#da6304',
    BananaMania:'#fae7b5',
    BananaYellow:'#ffe135',
    Bandicoot:'#858470',
    Barberry:'#ded717',
    BarbiePink:'#e0218a',
    BarleyCorn:'#a68b5b',
    BarleyWhite:'#fff4ce',
    BarnRed:'#7c0a02',
    Barossa:'#44012d',
    Bastille:'#292130',
    BattleshipGray:'#828f72',
    BayLeaf:'#7da98d',
    BayofMany:'#273a81',
    Bazaar:'#98777b',
    BdazzledBlue:'#2e5894',
    BeauBlue:'#bcd4e6',
    BeautyBush:'#eec1be',
    Beaver:'#9f8170',
    Beeswax:'#fef2c7',
    Beige:'#f5f5dc',
    Belgion:'#add8ff',
    Bermuda:'#7dd8c6',
    BermudaGray:'#6b8ba2',
    BerylGreen:'#dee5c0',
    Bianca:'#fcfbf3',
    BigDipOruby:'#9c2542',
    BigFootFeet:'#e88e5a',
    BigStone:'#162a40',
    Bilbao:'#327c14',
    BilobaFlower:'#b2a1ea',
    Birch:'#373021',
    BirdFlower:'#d4cd16',
    Biscay:'#1b3162',
    Bismark:'#497183',
    BisonHide:'#c1b7a4',
    Bisque:'#ffe4c4',
    Bistre:'#3d2b1f',
    Bitter:'#868974',
    BitterLemon:'#cae00d',
    Bittersweet:'#fe6f5e',
    BittersweetShimmer:'#bf4f51',
    Bizarre:'#eededa',
    Black:'#000000',
    BlackBean:'#3d0c02',
    BlackCoral:'#54626f',
    BlackForest:'#0b1304',
    BlackHaze:'#f6f7f7',
    BlackLeatherJacket:'#253529',
    BlackMarlin:'#3e2c1c',
    BlackOlive:'#3b3c36',
    BlackPearl:'#041322',
    BlackRock:'#0d0332',
    BlackRose:'#67032d',
    BlackRussian:'#0a001c',
    BlackShadows:'#bfafb2',
    BlackSqueeze:'#f2fafa',
    BlackWhite:'#fffef6',
    Blackberry:'#4d0135',
    Blackcurrant:'#32293a',
    BlanchedAlmond:'#ffebcd',
    BlastOffBronze:'#a57164',
    BlazeOrange:'#ff6700',
    BleachWhite:'#fef3d8',
    BleachedCedar:'#2c2133',
    BleuDeFrance:'#318ce7',
    BlizzardBlue:'#a3e3ed',
    Blond:'#faf0be',
    Blossom:'#dcb4bc',
    Blue:'#0000ff',
    BlueBayoux:'#496679',
    BlueBell:'#a2a2d0',
    BlueChalk:'#f1e9ff',
    BlueCharcoal:'#010d1a',
    BlueChill:'#0c8990',
    BlueDiamond:'#380474',
    BlueDianne:'#204852',
    BlueGem:'#2c0e8c',
    BlueGray:'#6699cc',
    BlueGreen:'#0d98ba',
    BlueHaze:'#bfbed8',
    BlueJeans:'#5dadec',
    BlueLagoon:'#ace5ee',
    BlueMagentaViolet:'#553592',
    BlueMarguerite:'#7666c6',
    BlueRibbon:'#0066ff',
    BlueRomance:'#d2f6de',
    BlueSapphire:'#126180',
    BlueSmoke:'#748881',
    BlueStone:'#016162',
    BlueViolet:'#8a2be2',
    BlueWhale:'#042e4c',
    BlueYonder:'#5072a7',
    BlueZodiac:'#13264d',
    Blueberry:'#4f86f7',
    Bluebonnet:'#1c1cf0',
    Blumine:'#18587a',
    Blush:'#de5d83',
    Bole:'#79443b',
    Bombay:'#afb1b8',
    BonJour:'#e5e0e1',
    BondiBlue:'#0095b6',
    Bone:'#e3dac9',
    BoogerBuster:'#dde26a',
    Bordeaux:'#5c0120',
    Bossanova:'#4e2a5a',
    BostonBlue:'#3b91b4',
    BostonUniversityRed:'#cc0000',
    Botticelli:'#c7dde5',
    BottleGreen:'#006a4e',
    Boulder:'#7a7a7a',
    Bouquet:'#ae809e',
    Bourbon:'#ba6f1e',
    Boysenberry:'#873260',
    Bracken:'#4a2a04',
    BrandeisBlue:'#0070ff',
    Brandy:'#dec196',
    BrandyPunch:'#cd8429',
    BrandyRose:'#bb8983',
    Brass:'#b5a642',
    BreakerBay:'#5da19f',
    BrickRed:'#cb4154',
    BridalHeath:'#fffaf4',
    Bridesmaid:'#fef0ec',
    BrightCerulean:'#1dacd6',
    BrightGray:'#3c4151',
    BrightGreen:'#66ff00',
    BrightLavender:'#bf94e4',
    BrightLilac:'#d891ef',
    BrightMaroon:'#c32148',
    BrightNavyBlue:'#1974d2',
    BrightRed:'#b10000',
    BrightSun:'#fed33c',
    BrightTurquoise:'#08e8de',
    BrightUbe:'#d19fe8',
    BrightYellow:'#ffaa1d',
    BrilliantAzure:'#3399ff',
    BrilliantLavender:'#f4bbff',
    BrilliantRose:'#ff55a3',
    BrinkPink:'#fb607f',
    BritishRacingGreen:'#004225',
    Bronco:'#aba196',
    Bronze:'#cd7f32',
    BronzeOlive:'#4e420c',
    BronzeYellow:'#737000',
    Bronzetone:'#4d400f',
    Broom:'#ffec13',
    Brown:'#964b00',
    BrownBramble:'#592804',
    BrownDerby:'#492615',
    BrownPod:'#401801',
    BrownRust:'#af593e',
    BrownSugar:'#af6e4d',
    BrownTumbleweed:'#37290e',
    BrownYellow:'#cc9966',
    BrunswickGreen:'#1b4d3e',
    BubbleGum:'#ffc1cc',
    Bubbles:'#e7feff',
    Buccaneer:'#622f30',
    Bud:'#a8ae9c',
    BudGreen:'#7bb661',
    BuddhaGold:'#c1a004',
    Buff:'#f0dc82',
    BulgarianRose:'#480607',
    BullShot:'#864d1e',
    Bunker:'#0d1117',
    Bunting:'#151f4c',
    Burgundy:'#800020',
    Burlywood:'#deb887',
    Burnham:'#002e20',
    BurningOrange:'#ff7034',
    BurningSand:'#d99376',
    BurnishedBrown:'#a17a74',
    BurntMaroon:'#420303',
    BurntOrange:'#cc5500',
    BurntSienna:'#e97451',
    BurntUmber:'#8a3324',
    Bush:'#0d2e1c',
    Buttercup:'#f3ad16',
    ButteredRum:'#a1750d',
    ButterflyBush:'#624e9a',
    Buttermilk:'#fff1b5',
    ButteryWhite:'#fffcea',
    Byzantine:'#bd33a4',
    Byzantium:'#702963',
    CGBlue:'#007aa5',
    CGRed:'#e03c31',
    CabSav:'#4d0a18',
    Cabaret:'#d94972',
    CabbagePont:'#3f4c3a',
    Cactus:'#587156',
    Cadet:'#536872',
    CadetBlue:'#5f9ea0',
    CadetGrey:'#91a3b0',
    Cadillac:'#b04c6a',
    CadmiumGreen:'#006b3c',
    CadmiumOrange:'#ed872d',
    CadmiumRed:'#e30022',
    CadmiumYellow:'#fff600',
    CafeNoir:'#4b3621',
    CafeRoyale:'#6f440c',
    CalPolyGreen:'#1e4d2b',
    Calico:'#e0c095',
    California:'#fe9d04',
    Calypso:'#31728d',
    Camarone:'#00581a',
    CambridgeBlue:'#a3c1ad',
    Camelot:'#893456',
    Cameo:'#d9b99b',
    CameoPink:'#efbbcc',
    Camouflage:'#3c3910',
    CamouflageGreen:'#78866b',
    CanCan:'#d591a4',
    Canary:'#f3fb62',
    CanaryYellow:'#ffef00',
    Candlelight:'#fcd917',
    CandyAppleRed:'#ff0800',
    CannonBlack:'#251706',
    CannonPink:'#894367',
    CapeCod:'#3c4443',
    CapeHoney:'#fee5ac',
    CapePalliser:'#a26645',
    Caper:'#dcedb4',
    Capri:'#00bfff',
    CaputMortuum:'#592720',
    Caramel:'#ffddaf',
    Cararra:'#eeeee8',
    CardinGreen:'#01361c',
    Cardinal:'#c41e3a',
    CardinalPink:'#8c055e',
    CareysPink:'#d29eaa',
    CaribbeanGreen:'#00cc99',
    Carissma:'#ea88a8',
    Carla:'#f3ffd8',
    Carmine:'#960018',
    CarminePink:'#eb4c42',
    CarmineRed:'#ff0038',
    CarnabyTan:'#5c2e01',
    Carnation:'#f95a61',
    CarnationPink:'#ffa6c9',
    Carnelian:'#b31b1b',
    CarolinaBlue:'#56a0d3',
    CarouselPink:'#f9e0ed',
    CarrotOrange:'#ed9121',
    Casablanca:'#f8b853',
    Casal:'#2f6168',
    Cascade:'#8ba9a5',
    Cashmere:'#e6bea5',
    Casper:'#adbed1',
    CastletonGreen:'#00563b',
    Castro:'#52001f',
    CatalinaBlue:'#062a78',
    Catawba:'#703642',
    CatskillWhite:'#eef6f7',
    CavernPink:'#e3bebe',
    Cedar:'#3e1c14',
    CedarChest:'#c95a49',
    CedarWoodFinish:'#711a00',
    Ceil:'#92a1cf',
    Celadon:'#ace1af',
    CeladonGreen:'#2f847c',
    Celery:'#b8c25d',
    Celeste:'#b2ffff',
    CelestialBlue:'#4997d0',
    Cello:'#1e385b',
    Celtic:'#163222',
    Cement:'#8d7662',
    Ceramic:'#fcfff9',
    Cerise:'#de3163',
    CerisePink:'#ec3b83',
    Cerulean:'#007ba7',
    CeruleanBlue:'#2a52be',
    CeruleanFrost:'#6d9bc3',
    Chablis:'#fff4f3',
    ChaletGreen:'#516e3d',
    Chalky:'#eed794',
    Chambray:'#354e8c',
    Chamois:'#eddcb1',
    Chamoisee:'#a0785a',
    Champagne:'#f7e7ce',
    Chantilly:'#f8c3df',
    Charade:'#292937',
    Charcoal:'#36454f',
    Chardon:'#fff3f1',
    Chardonnay:'#ffcd8c',
    CharlestonGreen:'#232b2b',
    Charlotte:'#baeef9',
    Charm:'#d47494',
    CharmPink:'#e68fac',
    Chartreuse:'#dfff00',
    ChateauGreen:'#40a860',
    Chatelle:'#bdb3c7',
    ChathamsBlue:'#175579',
    ChelseaCucumber:'#83aa5d',
    ChelseaGem:'#9e5302',
    Chenin:'#dfcd6f',
    Cherokee:'#fcda98',
    CherryBlossomPink:'#ffb7c5',
    CherryPie:'#2a0359',
    Cherrywood:'#651a14',
    Cherub:'#f8d9e9',
    Chestnut:'#954535',
    ChetwodeBlue:'#8581d9',
    Chicago:'#5d5c58',
    Chiffon:'#f1ffc8',
    ChileanFire:'#f77703',
    ChileanHeath:'#fffde6',
    ChinaIvory:'#fcffe7',
    ChinaRose:'#a8516e',
    ChineseRed:'#aa381e',
    ChineseViolet:'#856088',
    Chino:'#cec7a7',
    Chinook:'#a8e3bd',
    ChlorophyllGreen:'#4aff00',
    Chocolate:'#7b3f00',
    Christalle:'#33036b',
    Christi:'#67a712',
    Christine:'#e7730a',
    ChromeWhite:'#e8f1d4',
    ChromeYellow:'#ffa700',
    Cinder:'#0e0e18',
    Cinderella:'#fde1dc',
    Cinereous:'#98817b',
    Cinnabar:'#e34234',
    CinnamonSatin:'#cd607e',
    Cioccolato:'#55280c',
    Citrine:'#e4d00a',
    CitrineWhite:'#faf7d6',
    Citron:'#9fa91f',
    Citrus:'#a1c50a',
    Clairvoyant:'#480656',
    ClamShell:'#d4b6af',
    Claret:'#7f1734',
    ClassicRose:'#fbcce7',
    ClayAsh:'#bdc8b3',
    ClayCreek:'#8a8360',
    ClearDay:'#e9fffd',
    Clementine:'#e96e00',
    Clinker:'#371d09',
    Cloud:'#c7c4bf',
    CloudBurst:'#202e54',
    Cloudy:'#aca59f',
    Clover:'#384910',
    CobaltBlue:'#0047ab',
    CocoaBean:'#481c1c',
    CocoaBrown:'#d2691e',
    Coconut:'#965a3e',
    CoconutCream:'#f8f7dc',
    CodGray:'#0b0b0b',
    Coffee:'#6f4e37',
    CoffeeBean:'#2a140e',
    Cognac:'#9f381d',
    Cola:'#3f2500',
    ColdPurple:'#aba0d9',
    ColdTurkey:'#cebaba',
    ColonialWhite:'#ffedbc',
    ColumbiaBlue:'#c4d8e2',
    Comet:'#5c5d75',
    Como:'#517c66',
    Conch:'#c9d9d2',
    Concord:'#7c7b7a',
    Concrete:'#f2f2f2',
    Confetti:'#e9d75a',
    CongoBrown:'#593737',
    CongoPink:'#f88379',
    CongressBlue:'#02478e',
    Conifer:'#acdd4d',
    Contessa:'#c6726b',
    CoolBlack:'#002e63',
    CoolGrey:'#8c92ac',
    Copper:'#b87333',
    CopperCanyon:'#7e3a15',
    CopperPenny:'#ad6f69',
    CopperRed:'#cb6d51',
    CopperRose:'#996666',
    CopperRust:'#944747',
    Coquelicot:'#ff3800',
    Coral:'#ff7f50',
    CoralRed:'#ff4040',
    CoralReef:'#c7bca2',
    CoralTree:'#a86b6b',
    Cordovan:'#893f45',
    Corduroy:'#606e68',
    Coriander:'#c4d0b0',
    Cork:'#40291d',
    Corn:'#e7bf05',
    CornField:'#f8facd',
    CornHarvest:'#8b6b0b',
    CornflowerBlue:'#6495ed',
    CornflowerLilac:'#ffb0ac',
    Cornsilk:'#fff8dc',
    Corvette:'#fad3a2',
    Cosmic:'#76395d',
    CosmicCobalt:'#2e2d88',
    CosmicLatte:'#fff8e7',
    Cosmos:'#ffd8d9',
    CostaDelSol:'#615d30',
    CottonCandy:'#ffbcd9',
    CottonSeed:'#c2bdb6',
    CountyGreen:'#01371a',
    Cowboy:'#4d282d',
    CoyoteBrown:'#81613e',
    Crail:'#b95140',
    Cranberry:'#db5079',
    CraterBrown:'#462425',
    CrayolaBlue:'#1f75fe',
    CrayolaGreen:'#1cac78',
    CrayolaOrange:'#ff7538',
    CrayolaRed:'#ee204d',
    CrayolaYellow:'#fce883',
    Cream:'#fffdd0',
    CreamBrulee:'#ffe5a0',
    CreamCan:'#f5c85c',
    Creole:'#1e0f04',
    Crete:'#737829',
    Crimson:'#dc143c',
    CrimsonGlory:'#be0032',
    CrimsonRed:'#990000',
    Crocodile:'#736d58',
    CrownofThorns:'#771f1f',
    Crowshead:'#1c1208',
    Cruise:'#b5ecdf',
    Crusoe:'#004816',
    Crusta:'#fd7b33',
    Cumin:'#924321',
    Cumulus:'#fdffd5',
    Cupid:'#fbbeda',
    CuriousBlue:'#2596d1',
    CuttySark:'#507672',
    Cyan:'#00ffff',
    CyanAzure:'#4e82b4',
    CyanBlueAzure:'#4682bf',
    CyanCobaltBlue:'#28589c',
    CyanCornflowerBlue:'#188bc2',
    CyberGrape:'#58427c',
    CyberYellow:'#ffd300',
    Cyclamen:'#f56fa1',
    Cyprus:'#003e40',
    Daffodil:'#ffff31',
    Daintree:'#012731',
    DairyCream:'#f9e4bc',
    DaisyBush:'#4f2398',
    Dallas:'#6e4b26',
    Dandelion:'#f0e130',
    Danube:'#6093d1',
    DarkBlue:'#00008b',
    DarkBlueGray:'#666699',
    DarkBrown:'#654321',
    DarkBrownTangelo:'#88654e',
    DarkBurgundy:'#770f05',
    DarkByzantium:'#5d3954',
    DarkCandyAppleRed:'#a40000',
    DarkCerulean:'#08457e',
    DarkChestnut:'#986960',
    DarkCoral:'#cd5b45',
    DarkCyan:'#008b8b',
    DarkEbony:'#3c2005',
    DarkFern:'#0a480d',
    DarkGoldenrod:'#b8860b',
    DarkGreen:'#013220',
    DarkGunmetal:'#1f262a',
    DarkImperialBlue:'#6e6ef9',
    DarkJungleGreen:'#1a2421',
    DarkKhaki:'#bdb76b',
    DarkLavender:'#734f96',
    DarkLiver:'#534b4f',
    DarkMagenta:'#8b008b',
    DarkMediumGray:'#a9a9a9',
    DarkMidnightBlue:'#003366',
    DarkMossGreen:'#4a5d23',
    DarkOliveGreen:'#556b2f',
    DarkOrange:'#ff8c00',
    DarkOrchid:'#9932cc',
    DarkPastelBlue:'#779ecb',
    DarkPastelGreen:'#03c03c',
    DarkPastelPurple:'#966fd6',
    DarkPastelRed:'#c23b22',
    DarkPink:'#e75480',
    DarkPuce:'#4f3a3c',
    DarkPurple:'#301934',
    DarkRaspberry:'#872657',
    DarkRed:'#8b0000',
    DarkSalmon:'#e9967a',
    DarkScarlet:'#560319',
    DarkSeaGreen:'#8fbc8f',
    DarkSienna:'#3c1414',
    DarkSkyBlue:'#8cbed6',
    DarkSlateBlue:'#483d8b',
    DarkSlateGray:'#2f4f4f',
    DarkSpringGreen:'#177245',
    DarkTan:'#918151',
    DarkTangerine:'#ffa812',
    DarkTerraCotta:'#cc4e5c',
    DarkTurquoise:'#00ced1',
    DarkVanilla:'#d1bea8',
    DarkViolet:'#9400d3',
    DarkYellow:'#9b870c',
    DartmouthGreen:'#00703c',
    DavysGrey:'#555555',
    Dawn:'#a6a29a',
    DawnPink:'#f3e9e5',
    DeYork:'#7ac488',
    DebianRed:'#d70a53',
    Deco:'#d2da97',
    DeepBlue:'#220878',
    DeepBlush:'#e47698',
    DeepBronze:'#4a3004',
    DeepCarmine:'#a9203e',
    DeepCarminePink:'#ef3038',
    DeepCarrotOrange:'#e9692c',
    DeepCerise:'#da3287',
    DeepChestnut:'#b94e48',
    DeepCove:'#051040',
    DeepFir:'#002900',
    DeepForestGreen:'#182d09',
    DeepFuchsia:'#c154c1',
    DeepGreen:'#056608',
    DeepGreenCyanTurquoise:'#0e7c61',
    DeepJungleGreen:'#004b49',
    DeepKoamaru:'#333366',
    DeepLemon:'#f5c71a',
    DeepLilac:'#9955bb',
    DeepMagenta:'#cc00cc',
    DeepMaroon:'#820000',
    DeepOak:'#412010',
    DeepPink:'#ff1493',
    DeepPuce:'#a95c68',
    DeepRed:'#850101',
    DeepRuby:'#843f5b',
    DeepSaffron:'#ff9933',
    DeepSapphire:'#082567',
    DeepSea:'#01826b',
    DeepSeaGreen:'#095859',
    DeepSpaceSparkle:'#4a646c',
    DeepTaupe:'#7e5e60',
    DeepTeal:'#003532',
    DeepTuscanRed:'#66424d',
    DeepViolet:'#330066',
    Deer:'#ba8759',
    DelRio:'#b09a95',
    Dell:'#396413',
    Delta:'#a4a49d',
    Deluge:'#7563a8',
    Denim:'#1560bd',
    DenimBlue:'#2243b6',
    Derby:'#ffeed8',
    DesaturatedCyan:'#669999',
    Desert:'#ae6020',
    DesertSand:'#edc9af',
    DesertStorm:'#f8f8f7',
    Desire:'#ea3c53',
    Dew:'#eafffe',
    DiSerria:'#db995e',
    Diamond:'#b9f2ff',
    Diesel:'#130000',
    DimGray:'#696969',
    Dingley:'#5d7747',
    DingyDungeon:'#c53151',
    Dirt:'#9b7653',
    Disco:'#871550',
    Dixie:'#e29418',
    DodgerBlue:'#1e90ff',
    Dogs:'#b86d29',
    DogwoodRose:'#d71868',
    DollarBill:'#85bb65',
    Dolly:'#f9ff8b',
    Dolphin:'#646077',
    Domino:'#8e775e',
    DonJuan:'#5d4c51',
    DonkeyBrown:'#664c28',
    Dorado:'#6b5755',
    DoubleColonialWhite:'#eee3ad',
    DoublePearlLusta:'#fcf4d0',
    DoubleSpanishWhite:'#e6d7b9',
    DoveGray:'#6d6c6c',
    Downriver:'#092256',
    Downy:'#6fd0c5',
    Driftwood:'#af8751',
    Drover:'#fdf7ad',
    DukeBlue:'#00009c',
    DullLavender:'#a899e6',
    Dune:'#383533',
    DustStorm:'#e5ccc9',
    DustyGray:'#a8989b',
    DutchWhite:'#efdfbb',
    Eagle:'#b6baa4',
    EagleGreen:'#004953',
    EarlsGreen:'#c9b93b',
    EarlyDawn:'#fff9e6',
    EarthYellow:'#e1a95f',
    EastBay:'#414c7d',
    EastSide:'#ac91ce',
    EasternBlue:'#1e9ab0',
    Ebb:'#e9e3e3',
    Ebony:'#555d50',
    EbonyClay:'#26283b',
    Eclipse:'#311c17',
    Ecru:'#c2b280',
    EcruWhite:'#f5f3e5',
    Ecstasy:'#fa7814',
    Eden:'#105852',
    Edgewater:'#c8e3d7',
    Edward:'#a2aeab',
    EerieBlack:'#1b1b1b',
    EggSour:'#fff4dd',
    EggWhite:'#ffefc1',
    Eggplant:'#614051',
    Eggshell:'#f0ead6',
    EgyptianBlue:'#1034a6',
    ElPaso:'#1e1708',
    ElSalva:'#8f3e33',
    ElectricBlue:'#7df9ff',
    ElectricCrimson:'#ff003f',
    ElectricIndigo:'#6f00ff',
    ElectricLime:'#ccff00',
    ElectricPurple:'#bf00ff',
    ElectricViolet:'#8b00ff',
    ElectricYellow:'#ffff33',
    Elephant:'#123447',
    ElfGreen:'#088370',
    Elm:'#1c7c7d',
    Emerald:'#50c878',
    Eminence:'#6c3082',
    Emperor:'#514649',
    Empress:'#817377',
    Endeavour:'#0056a7',
    EnergyYellow:'#f8dd5c',
    EngineeringInternationalOrange:'#ba160c',
    EnglishHolly:'#022d15',
    EnglishLavender:'#b48395',
    EnglishRed:'#ab4b52',
    EnglishVermillion:'#cc474b',
    EnglishWalnut:'#3e2b23',
    Envy:'#8ba690',
    Equator:'#e1bc64',
    Espresso:'#612718',
    Eternity:'#211a0e',
    EtonBlue:'#96c8a2',
    Eucalyptus:'#44d7a8',
    Eunry:'#cfa39d',
    EveningSea:'#024e46',
    Everglade:'#1c402e',
    FOGRA29RichBlack:'#010b13',
    FOGRA39RichBlack:'#010203',
    FadedJade:'#427977',
    FairPink:'#ffefec',
    Falcon:'#7f626d',
    Fallow:'#c19a6b',
    FaluRed:'#801818',
    Fandango:'#b53389',
    FandangoPink:'#de5285',
    Fantasy:'#faf3f0',
    FashionFuchsia:'#f400a1',
    Fawn:'#e5aa70',
    Fedora:'#796a78',
    Feijoa:'#9fdd8c',
    Feldgrau:'#4d5d53',
    Fern:'#63b76c',
    FernFrond:'#657220',
    FernGreen:'#4f7942',
    Ferra:'#704f50',
    FerrariRed:'#ff2800',
    Festival:'#fbe96c',
    Feta:'#f0fcea',
    FieldDrab:'#6c541e',
    FieryOrange:'#b35213',
    FieryRose:'#ff5470',
    Finch:'#626649',
    Finlandia:'#556d56',
    Finn:'#692d54',
    Fiord:'#405169',
    Fire:'#aa4203',
    FireBush:'#e89928',
    FireEngineRed:'#ce2029',
    Firebrick:'#b22222',
    Firefly:'#0e2a30',
    Flame:'#e25822',
    FlamePea:'#da5b38',
    Flamenco:'#ff7d07',
    Flamingo:'#f2552a',
    FlamingoPink:'#fc8eac',
    Flavescent:'#f7e98e',
    Flax:'#eedc82',
    FlaxSmoke:'#7b8265',
    Flint:'#6f6a61',
    Flirt:'#a2006d',
    FloralWhite:'#fffaf0',
    FlushMahogany:'#ca3435',
    Foam:'#d8fcfa',
    Fog:'#d7d0ff',
    FoggyGray:'#cbcab6',
    Folly:'#ff004f',
    ForestGreen:'#228b22',
    ForgetMeNot:'#fff1ee',
    FountainBlue:'#56b4be',
    Frangipani:'#ffdeb3',
    FrenchBistre:'#856d4d',
    FrenchBlue:'#0072bb',
    FrenchFuchsia:'#fd3f92',
    FrenchGray:'#bdbdc6',
    FrenchLilac:'#86608e',
    FrenchLime:'#9efd38',
    FrenchMauve:'#d473d4',
    FrenchPass:'#bdedfd',
    FrenchPink:'#fd6c9e',
    FrenchPlum:'#811453',
    FrenchPuce:'#4e1609',
    FrenchRaspberry:'#c72c48',
    FrenchRose:'#f64a8a',
    FrenchSkyBlue:'#77b5fe',
    FrenchViolet:'#8806ce',
    FrenchWine:'#ac1e44',
    FreshAir:'#a6e7ff',
    FreshEggplant:'#990066',
    FriarGray:'#807e79',
    FringyFlower:'#b1e2c1',
    Froly:'#f57584',
    Frost:'#edf5dd',
    Frostbite:'#e936a7',
    FrostedMint:'#dbfff8',
    Frostee:'#e4f6e7',
    FruitSalad:'#4f9d5d',
    Fuchsia:'#ff00ff',
    FuchsiaBlue:'#7a58c1',
    FuchsiaPink:'#ff77ff',
    FuchsiaPurple:'#cc397b',
    FuchsiaRose:'#c74375',
    Fuego:'#bede0d',
    FuelYellow:'#eca927',
    Fulvous:'#e48400',
    FunBlue:'#1959a8',
    FunGreen:'#016d39',
    FuscousGray:'#54534d',
    FuzzyWuzzy:'#cc6666',
    FuzzyWuzzyBrown:'#c45655',
    GOGreen:'#00ab66',
    GableGreen:'#163531',
    Gainsboro:'#dcdcdc',
    Gallery:'#efefef',
    Galliano:'#dcb20c',
    Gamboge:'#e49b0f',
    GambogeOrange:'#996600',
    GargoyleGas:'#ffdf46',
    Geebung:'#d18f1b',
    GenericViridian:'#007f66',
    Genoa:'#15736b',
    Geraldine:'#fb8989',
    Geyser:'#d4dfe2',
    Ghost:'#c7c9d5',
    GhostWhite:'#f8f8ff',
    GiantsClub:'#b05c52',
    GiantsOrange:'#fe5a1d',
    Gigas:'#523c94',
    Gimblet:'#b8b56a',
    Gin:'#e8f2eb',
    GinFizz:'#fff9e2',
    Ginger:'#b06500',
    Givry:'#f8e4bf',
    Glacier:'#80b3c4',
    GladeGreen:'#61845f',
    Glaucous:'#6082b6',
    Glitter:'#e6e8fa',
    GlossyGrape:'#ab92b3',
    GoBen:'#726d4e',
    Goblin:'#3d7d52',
    GoldDrop:'#f18200',
    GoldFusion:'#85754e',
    GoldTips:'#deba13',
    Golden:'#ffd700',
    GoldenBell:'#e28913',
    GoldenBrown:'#996515',
    GoldenDream:'#f0d52d',
    GoldenFizz:'#f5fb3d',
    GoldenGateBridge:'#c0362c',
    GoldenGlow:'#fde295',
    GoldenPoppy:'#fcc200',
    GoldenSand:'#f0db7d',
    GoldenTainoi:'#ffcc5c',
    GoldenYellow:'#ffdf00',
    Goldenrod:'#daa520',
    Gondola:'#261414',
    GordonsGreen:'#0b1107',
    Gorse:'#fff14f',
    Gossamer:'#069b81',
    Gossip:'#d2f8b0',
    Gothic:'#6d92a1',
    GovernorBay:'#2f3cb3',
    GrainBrown:'#e4d5b7',
    Grandis:'#ffd38c',
    GraniteGray:'#676767',
    GraniteGreen:'#8d8974',
    GrannyApple:'#d5f6e3',
    GrannySmith:'#84a0a0',
    GrannySmithApple:'#a8e4a0',
    Grape:'#6f2da8',
    Graphite:'#251607',
    Gravel:'#4a444b',
    Gray:'#808080',
    GrayAsparagus:'#465945',
    GrayChateau:'#a2aab3',
    GrayNickel:'#c3c3bd',
    GrayNurse:'#e7ece6',
    GrayOlive:'#a9a491',
    GraySuit:'#c1becd',
    Green:'#00ff00',
    GreenBlue:'#1164b4',
    GreenCyan:'#009966',
    GreenHaze:'#01a368',
    GreenHouse:'#24500f',
    GreenKelp:'#25311c',
    GreenLeaf:'#436a0d',
    GreenLizard:'#a7f432',
    GreenMist:'#cbd3b0',
    GreenPea:'#1d6142',
    GreenSheen:'#6eaea1',
    GreenSmoke:'#a4af6e',
    GreenSpring:'#b8c1b1',
    GreenVogue:'#032b52',
    GreenWaterloo:'#101405',
    GreenWhite:'#e8ebe0',
    GreenYellow:'#adff2f',
    Grenadier:'#d54600',
    Grizzly:'#885818',
    Grullo:'#a99a86',
    GuardsmanRed:'#ba0101',
    GulfBlue:'#051657',
    GulfStream:'#80b3ae',
    GullGray:'#9dacb7',
    GumLeaf:'#b6d3bf',
    Gumbo:'#7ca1a6',
    GunPowder:'#414257',
    Gunmetal:'#2a3439',
    Gunsmoke:'#828685',
    Gurkha:'#9a9577',
    Hacienda:'#98811b',
    HairyHeath:'#6b2a14',
    Haiti:'#1b1035',
    HalayàÚbe:'#663854',
    HalfBaked:'#85c4cc',
    HalfColonialWhite:'#fdf6d3',
    HalfDutchWhite:'#fef7de',
    HalfSpanishWhite:'#fef4db',
    HalfandHalf:'#fffee1',
    Hampton:'#e5d8af',
    HanBlue:'#446ccf',
    HanPurple:'#5218fa',
    Harlequin:'#3fff00',
    HarlequinGreen:'#46cb18',
    Harp:'#e6f2ea',
    HarvardCrimson:'#c90016',
    HarvestGold:'#da9100',
    HavelockBlue:'#5590d9',
    HawaiianTan:'#9d5616',
    HawkesBlue:'#d4e2fc',
    HeatWave:'#ff7a00',
    Heath:'#541012',
    Heather:'#b7c3d0',
    HeatheredGray:'#b6b095',
    HeavyMetal:'#2b3228',
    Heliotrope:'#df73ff',
    HeliotropeGray:'#aa98a9',
    HeliotropeMagenta:'#aa00bb',
    Hemlock:'#5e5d3b',
    Hemp:'#907874',
    Hibiscus:'#b6316c',
    Highland:'#6f8e63',
    Hillary:'#aca586',
    Himalaya:'#6a5d1b',
    HintofGreen:'#e6ffe9',
    HintofRed:'#fbf9f9',
    HintofYellow:'#fafde4',
    HippieBlue:'#589aaf',
    HippieGreen:'#53824b',
    HippiePink:'#ae4560',
    HitGray:'#a1adb5',
    HitPink:'#ffab81',
    HokeyPokey:'#c8a528',
    Hoki:'#65869f',
    Holly:'#011d13',
    HoneyFlower:'#4f1c70',
    Honeydew:'#f0fff0',
    Honeysuckle:'#edfc84',
    HonoluluBlue:'#006db0',
    HookersGreen:'#49796b',
    Hopbush:'#d06da1',
    Horizon:'#5a87a0',
    Horses:'#543d37',
    HorsesNeck:'#604913',
    HotMagenta:'#ff1dce',
    HotPink:'#ff69b4',
    HotToddy:'#b38007',
    HummingBird:'#cff9f3',
    HunterGreen:'#355e3b',
    Hurricane:'#877c7b',
    Husk:'#b7a458',
    IceCold:'#b1f4e7',
    Iceberg:'#71a6d2',
    Icterine:'#fcf75e',
    IlluminatingEmerald:'#319177',
    Illusion:'#f6a4c9',
    Imperial:'#602f6b',
    ImperialBlue:'#002395',
    ImperialRed:'#ed2939',
    InchWorm:'#b0e313',
    Inchworm:'#b2ec5d',
    Independence:'#4c516d',
    IndiaGreen:'#138808',
    IndianRed:'#cd5c5c',
    IndianTan:'#4d1e01',
    IndianYellow:'#e3a857',
    Indigo:'#4b0082',
    IndigoDye:'#091f92',
    Indochine:'#c26b03',
    InternationalKleinBlue:'#002fa7',
    InternationalOrange:'#ff4f00',
    Iris:'#5a4fcf',
    IrishCoffee:'#5f3d26',
    Iroko:'#433120',
    Iron:'#d4d7d9',
    IronsideGray:'#676662',
    Ironstone:'#86483c',
    Irresistible:'#b3446c',
    Isabelline:'#f4f0ec',
    IslamicGreen:'#009000',
    IslandSpice:'#fffcee',
    Ivory:'#fffff0',
    Jacaranda:'#2e0329',
    Jacarta:'#3a2a6a',
    JackoBean:'#2e1905',
    JacksonsPurple:'#20208d',
    Jade:'#00a86b',
    Jaffa:'#ef863f',
    JaggedIce:'#c2e8e5',
    Jagger:'#350e57',
    Jaguar:'#080110',
    Jambalaya:'#5b3013',
    Janna:'#f4ebd3',
    JapaneseCarmine:'#9d2933',
    JapaneseIndigo:'#264348',
    JapaneseLaurel:'#0a6906',
    JapaneseMaple:'#780109',
    JapaneseViolet:'#5b3256',
    Japonica:'#d87c63',
    Jasmine:'#f8de7e',
    Jasper:'#d73b3e',
    Java:'#1fc2c2',
    JazzberryJam:'#a50b5e',
    JellyBean:'#da614e',
    Jet:'#343434',
    JetStream:'#b5d2ce',
    Jewel:'#126b40',
    Jon:'#3b1f1f',
    Jonquil:'#f4ca16',
    JordyBlue:'#8ab9f1',
    JudgeGray:'#544333',
    Jumbo:'#7c7b82',
    JuneBud:'#bdda57',
    JungleGreen:'#29ab87',
    JungleMist:'#b4cfd3',
    Juniper:'#6d9292',
    JustRight:'#eccdb9',
    KUCrimson:'#e8000d',
    Kabul:'#5e483e',
    KaitokeGreen:'#004620',
    Kangaroo:'#c6c8bd',
    Karaka:'#1e1609',
    Karry:'#ffead4',
    KashmirBlue:'#507096',
    KellyGreen:'#4cbb17',
    Kelp:'#454936',
    KenyanCopper:'#7c1c05',
    Keppel:'#3ab09e',
    KeyLime:'#e8f48c',
    KeyLimePie:'#bfc921',
    Khaki:'#c3b091',
    Kidnapper:'#e1ead4',
    Kilamanjaro:'#240c02',
    Killarney:'#3a6a47',
    Kimberly:'#736c9f',
    KingfisherDaisy:'#3e0480',
    Kobi:'#e79fc4',
    Kobicha:'#6b4423',
    Kokoda:'#6e6d57',
    KombuGreen:'#354230',
    Korma:'#8f4b0e',
    Koromiko:'#ffbd5f',
    Kournikova:'#ffe772',
    Kumera:'#886221',
    LaPalma:'#368716',
    LaRioja:'#b3c110',
    LaSalleGreen:'#087830',
    LanguidLavender:'#d6cadd',
    LapisLazuli:'#26619c',
    LasPalmas:'#c6e610',
    Laser:'#c8b568',
    Laurel:'#749378',
    LaurelGreen:'#a9ba9d',
    Lava:'#cf1020',
    Lavender:'#b57edc',
    LavenderBlush:'#fff0f5',
    LavenderGray:'#c4c3d0',
    LavenderIndigo:'#9457eb',
    LavenderMagenta:'#ee82ee',
    LavenderMist:'#e6e6fa',
    LavenderPink:'#fbaed2',
    LavenderPurple:'#967bb6',
    LavenderRose:'#fba0e3',
    LawnGreen:'#7cfc00',
    Leather:'#967059',
    Lemon:'#fff700',
    LemonChiffon:'#fffacd',
    LemonCurry:'#cca01d',
    LemonGinger:'#ac9e22',
    LemonGlacier:'#fdff00',
    LemonGrass:'#9b9e8f',
    LemonLime:'#e3ff00',
    LemonMeringue:'#f6eabe',
    LemonYellow:'#fff44f',
    Lenurple:'#ba93d8',
    Liberty:'#545aa7',
    Licorice:'#1a1110',
    LightApricot:'#fdd5b1',
    LightBlue:'#add8e6',
    LightBrilliantRed:'#fe2e2e',
    LightBrown:'#b5651d',
    LightCarminePink:'#e66771',
    LightCobaltBlue:'#88ace0',
    LightCoral:'#f08080',
    LightCornflowerBlue:'#93ccea',
    LightCrimson:'#f56991',
    LightCyan:'#e0ffff',
    LightDeepPink:'#ff5ccd',
    LightFrenchBeige:'#c8ad7f',
    LightFuchsiaPink:'#f984ef',
    LightGoldenrodYellow:'#fafad2',
    LightGray:'#d3d3d3',
    LightGrayishMagenta:'#cc99cc',
    LightGreen:'#90ee90',
    LightHotPink:'#ffb3de',
    LightKhaki:'#f0e68c',
    LightMediumOrchid:'#d39bcb',
    LightMossGreen:'#addfad',
    LightOrchid:'#e6a8d7',
    LightPastelPurple:'#b19cd9',
    LightPink:'#ffb6c1',
    LightSalmon:'#ffa07a',
    LightSalmonPink:'#ff9999',
    LightSeaGreen:'#20b2aa',
    LightSkyBlue:'#87cefa',
    LightSlateGray:'#778899',
    LightSteelBlue:'#b0c4de',
    LightTaupe:'#b38b6d',
    LightTurquoise:'#afeeee',
    LightYellow:'#ffffe0',
    LightningYellow:'#fcc01e',
    Lilac:'#c8a2c8',
    LilacBush:'#9874d3',
    LilacLuster:'#ae98aa',
    Lily:'#c8aabf',
    LilyWhite:'#e7f8ff',
    Lima:'#76bd17',
    Lime:'#bfff00',
    LimeGreen:'#32cd32',
    Limeade:'#6f9d02',
    LimedAsh:'#747d63',
    LimedOak:'#ac8a56',
    LimedSpruce:'#394851',
    Limerick:'#9dc209',
    LincolnGreen:'#195905',
    Linen:'#faf0e6',
    LinkWater:'#d9e4f5',
    Lipstick:'#ab0563',
    LisbonBrown:'#423921',
    LittleBoyBlue:'#6ca0dc',
    Liver:'#674c47',
    LiverChestnut:'#987456',
    LividBrown:'#4d282e',
    Loafer:'#eef4de',
    Loblolly:'#bdc9ce',
    Lochinvar:'#2c8c84',
    Lochmara:'#007ec7',
    Locust:'#a8af8e',
    LogCabin:'#242a1d',
    Logan:'#aaa9cd',
    Lola:'#dfcfdb',
    LondonHue:'#bea6c3',
    Lonestar:'#6d0101',
    Lotus:'#863c3c',
    Loulou:'#460b41',
    Lucky:'#af9f1c',
    LuckyPoint:'#1a1a68',
    Lumber:'#ffe4cd',
    LunarGreen:'#3c493a',
    Lust:'#e62020',
    LuxorGold:'#a7882c',
    Lynch:'#697e9a',
    MSUGreen:'#18453b',
    Mabel:'#d9f7ff',
    MacaroniAndCheese:'#ffbd88',
    MacaroniandCheese:'#ffb97b',
    Madang:'#b7f0be',
    Madison:'#09255d',
    Madras:'#3f3002',
    Magenta:'#ca1f7b',
    MagentaHaze:'#9f4576',
    MagentaPink:'#cc338b',
    MagicMint:'#aaf0d1',
    MagicPotion:'#ff4466',
    Magnolia:'#f8f4ff',
    Mahogany:'#c04000',
    MaiTai:'#b06608',
    Maize:'#fbec5d',
    MajorelleBlue:'#6050dc',
    Makara:'#897d6d',
    Mako:'#444954',
    Malachite:'#0bda51',
    Malibu:'#7dc8f7',
    Mallard:'#233418',
    Malta:'#bdb2a1',
    Mamba:'#8e8190',
    Manatee:'#979aaa',
    Mandalay:'#ad781b',
    Mandarin:'#f37a48',
    Mandy:'#e25465',
    MandysPink:'#f2c3b2',
    MangoTango:'#ff8243',
    Manhattan:'#f5c999',
    Mantis:'#74c365',
    Mantle:'#8b9c90',
    Manz:'#eeef78',
    MardiGras:'#880085',
    Marigold:'#eaa221',
    MarigoldYellow:'#fbe870',
    Mariner:'#286acd',
    Maroon:'#800000',
    MaroonOak:'#520c17',
    Marshland:'#0b0f08',
    Martini:'#afa09e',
    Martinique:'#363050',
    Marzipan:'#f8db9d',
    Masala:'#403b38',
    Matisse:'#1b659d',
    Matrix:'#b05d54',
    Matterhorn:'#4e3b41',
    Mauve:'#e0b0ff',
    MauveTaupe:'#915f6d',
    Mauvelous:'#ef98aa',
    Maverick:'#d8c2d5',
    MaximumBlue:'#47abcc',
    MaximumYellow:'#fafa37',
    MayGreen:'#4c9141',
    MayaBlue:'#73c2fb',
    MeatBrown:'#e5b73b',
    MediumAquamarine:'#66ddaa',
    MediumBlue:'#0000cd',
    MediumCandyAppleRed:'#e2062c',
    MediumElectricBlue:'#035096',
    MediumJungleGreen:'#1c352d',
    MediumOrchid:'#ba55d3',
    MediumPurple:'#9370db',
    MediumRedViolet:'#bb3385',
    MediumRuby:'#aa4069',
    MediumSeaGreen:'#3cb371',
    MediumSkyBlue:'#80daeb',
    MediumSlateBlue:'#7b68ee',
    MediumSpringBud:'#c9dc87',
    MediumSpringGreen:'#00fa9a',
    MediumTurquoise:'#48d1cc',
    MediumVermilion:'#d9603b',
    Melanie:'#e4c2d5',
    Melanzane:'#300529',
    MellowApricot:'#f8b878',
    Melon:'#fdbcb4',
    Melrose:'#c7c1ff',
    Mercury:'#e5e5e5',
    Merino:'#f6f0e6',
    Merlin:'#413c37',
    Merlot:'#831923',
    MetalPink:'#ff00fd',
    MetallicBronze:'#49371b',
    MetallicCopper:'#71291d',
    MetallicGold:'#d4af37',
    MetallicSeaweed:'#0a7e8c',
    MetallicSunburst:'#9c7c38',
    Meteor:'#d07d12',
    Meteorite:'#3c1f76',
    MexicanPink:'#e4007c',
    MexicanRed:'#a72525',
    MidGray:'#5f5f6e',
    Midnight:'#702670',
    MidnightBlue:'#191970',
    MidnightMoss:'#041004',
    Mikado:'#2d2510',
    MikadoYellow:'#ffc40c',
    Milan:'#faffa4',
    MilanoRed:'#b81104',
    MilkPunch:'#fff6d4',
    Millbrook:'#594433',
    Mimosa:'#f8fdd3',
    Mindaro:'#e3f988',
    MineShaft:'#323232',
    MineralGreen:'#3f5d53',
    Ming:'#36747d',
    MinionYellow:'#f5e050',
    Minsk:'#3f307f',
    Mint:'#3eb489',
    MintCream:'#f5fffa',
    MintGreen:'#98ff98',
    MintJulep:'#f1eec1',
    MintTulip:'#c4f4eb',
    Mirage:'#161928',
    Mischka:'#d1d2dd',
    MistGray:'#c4c4bc',
    MistyMoss:'#bbb477',
    MistyRose:'#ffe4e1',
    Mobster:'#7f7589',
    Moccaccino:'#6e1d14',
    Moccasin:'#ffe4b5',
    Mocha:'#782d19',
    Mojo:'#c04737',
    MonaLisa:'#ffa194',
    Monarch:'#8b0723',
    Mondo:'#4a3c30',
    Mongoose:'#b5a27f',
    Monsoon:'#8a8389',
    MonteCarlo:'#83d0c6',
    Monza:'#c7031e',
    MoodyBlue:'#7f76d3',
    MoonGlow:'#fcfeda',
    MoonMist:'#dcddcc',
    MoonRaker:'#d6cef6',
    MoonstoneBlue:'#73a9c2',
    MordantRed:'#ae0c00',
    MorningGlory:'#9edee0',
    MoroccoBrown:'#441d00',
    Mortar:'#504351',
    Mosque:'#036a6e',
    MossGreen:'#8a9a5b',
    MountainMeadow:'#30ba8f',
    MountainMist:'#959396',
    MountbattenPink:'#997a8d',
    MuddyWaters:'#b78e5c',
    Muesli:'#aa8b5b',
    MughalGreen:'#306030',
    Mulberry:'#c54b8c',
    MulberryWood:'#5c0536',
    MuleFawn:'#8c472f',
    MulledWine:'#4e4562',
    MummysTomb:'#828e84',
    MunsellBlue:'#0093af',
    MunsellGreen:'#00a877',
    MunsellPurple:'#9f00c5',
    MunsellRed:'#f2003c',
    MunsellYellow:'#efcc00',
    Mustard:'#ffdb58',
    MyPink:'#d69188',
    MySin:'#ffb31f',
    MyrtleGreen:'#317873',
    Mystic:'#d65282',
    MysticMaroon:'#ad4379',
    NCSBlue:'#0087bd',
    NCSGreen:'#009f6b',
    NCSRed:'#c40233',
    NadeshikoPink:'#f6adc6',
    Nandor:'#4b5d52',
    Napa:'#aca494',
    NapierGreen:'#2a8000',
    NaplesYellow:'#fada5e',
    Narvik:'#edf9f1',
    NaturalGray:'#8b8680',
    NavajoWhite:'#ffdead',
    Navy:'#000080',
    Nebula:'#cbdbd6',
    Negroni:'#ffe2c5',
    NeonCarrot:'#ffa343',
    NeonFuchsia:'#fe4164',
    NeonGreen:'#39ff14',
    Nepal:'#8eabc1',
    Neptune:'#7cb7bb',
    Nero:'#140600',
    Nevada:'#646e75',
    NewCar:'#214fc6',
    NewOrleans:'#f3d69d',
    NewYorkPink:'#d7837f',
    Niagara:'#06a189',
    Nickel:'#727472',
    NightRider:'#1f120f',
    NightShadz:'#aa375a',
    NileBlue:'#193751',
    Nobel:'#b7b1b1',
    Nomad:'#bab1a2',
    NonPhotoBlue:'#a4dded',
    NorthTexasGreen:'#059033',
    Norway:'#a8bd9f',
    Nugget:'#c59922',
    Nutmeg:'#81422c',
    NutmegWoodFinish:'#683600',
    Nyanza:'#e9ffdb',
    Oasis:'#feefce',
    Observatory:'#02866f',
    OceanBlue:'#4f42b5',
    OceanBoatBlue:'#0077be',
    OceanGreen:'#48bf91',
    Ochre:'#cc7722',
    OffGreen:'#e6f8f3',
    OffYellow:'#fef9e3',
    OgreOdor:'#fd5240',
    Oil:'#281e15',
    OldBrick:'#901e1e',
    OldBurgundy:'#43302e',
    OldCopper:'#724a2f',
    OldGold:'#cfb53b',
    OldHeliotrope:'#563c5c',
    OldLace:'#fdf5e6',
    OldLavender:'#796878',
    OldMossGreen:'#867e36',
    OldRose:'#c08081',
    OldSilver:'#848482',
    Olive:'#808000',
    OliveDrab:'#6b8e23',
    OliveDrabSeven:'#3c341f',
    OliveGreen:'#b5b35c',
    OliveHaze:'#8b8470',
    Olivetone:'#716e10',
    Olivine:'#9ab973',
    Onahau:'#cdf4ff',
    Onion:'#2f270e',
    Onyx:'#353839',
    Opal:'#a9c6c2',
    OperaMauve:'#b784a7',
    Opium:'#8e6f70',
    Oracle:'#377475',
    Orange:'#ff7f00',
    OrangePeel:'#ff9f00',
    OrangeRed:'#ff4500',
    OrangeRoughy:'#c45719',
    OrangeSoda:'#fa5b3d',
    OrangeWhite:'#fefced',
    OrangeYellow:'#f8d568',
    Orchid:'#da70d6',
    OrchidPink:'#f2bdcd',
    OrchidWhite:'#fffdf3',
    Oregon:'#9b4703',
    Organ:'#6c2e1f',
    Orient:'#015e85',
    OrientalPink:'#c69191',
    Orinoco:'#f3fbd4',
    OriolesOrange:'#fb4f14',
    OsloGray:'#878d91',
    Ottoman:'#e9f8ed',
    OuterSpace:'#414a4c',
    OutrageousOrange:'#ff6e4a',
    OxfordBlue:'#002147',
    Oxley:'#779e86',
    OysterBay:'#dafaff',
    OysterPink:'#e9cecd',
    Paarl:'#a65529',
    Pablo:'#776f61',
    PacificBlue:'#1ca9c9',
    Pacifika:'#778120',
    Paco:'#411f10',
    Padua:'#ade6c4',
    PakistanGreen:'#006600',
    PalatinateBlue:'#273be2',
    PalatinatePurple:'#682860',
    PaleBrown:'#987654',
    PaleCanary:'#ffff99',
    PaleCarmine:'#af4035',
    PaleCerulean:'#9bc4e2',
    PaleChestnut:'#ddadaf',
    PaleCopper:'#da8a67',
    PaleCornflowerBlue:'#abcdef',
    PaleCyan:'#87d3f8',
    PaleGold:'#e6be8a',
    PaleGoldenrod:'#eee8aa',
    PaleGreen:'#98fb98',
    PaleLavender:'#dcd0ff',
    PaleLeaf:'#c0d3b9',
    PaleMagenta:'#f984e5',
    PaleMagentaPink:'#ff99cc',
    PaleOyster:'#988d77',
    PalePink:'#fadadd',
    PalePlum:'#dda0dd',
    PalePrim:'#fdfeb8',
    PaleRedViolet:'#db7093',
    PaleRobinEggBlue:'#96ded1',
    PaleRose:'#ffe1f2',
    PaleSilver:'#c9c0bb',
    PaleSky:'#6e7783',
    PaleSlate:'#c3bfc1',
    PaleSpringBud:'#ecebbd',
    PaleTaupe:'#bc987e',
    PaleViolet:'#cc99ff',
    PalmGreen:'#09230f',
    PalmLeaf:'#19330e',
    Pampas:'#f4f2ee',
    Panache:'#eaf6ee',
    Pancho:'#edcdab',
    PansyPurple:'#78184a',
    PantoneBlue:'#0018a8',
    PantoneGreen:'#00ad43',
    PantoneMagenta:'#d0417e',
    PantoneOrange:'#ff5800',
    PantonePink:'#d74894',
    PantoneYellow:'#fedf00',
    PaoloVeroneseGreen:'#009b7d',
    PapayaWhip:'#ffefd5',
    Paprika:'#8d0226',
    ParadisePink:'#e63e62',
    Paradiso:'#317d82',
    Parchment:'#f1e9d2',
    ParisDaisy:'#fff46e',
    ParisM:'#26056a',
    ParisWhite:'#cadcd4',
    Parsley:'#134f19',
    PastelBlue:'#aec6cf',
    PastelBrown:'#836953',
    PastelGray:'#cfcfc4',
    PastelGreen:'#77dd77',
    PastelMagenta:'#f49ac2',
    PastelOrange:'#ffb347',
    PastelPink:'#dea5a4',
    PastelPurple:'#b39eb5',
    PastelRed:'#ff6961',
    PastelViolet:'#cb99c9',
    PastelYellow:'#fdfd96',
    Patina:'#639a8f',
    PattensBlue:'#def5ff',
    Paua:'#260368',
    Pavlova:'#d7c498',
    PaynesGrey:'#536878',
    Peach:'#ffcba4',
    PeachCream:'#fff0db',
    PeachOrange:'#ffcc99',
    PeachPuff:'#ffdab9',
    PeachSchnapps:'#ffdcd6',
    PeachYellow:'#fadfad',
    Peanut:'#782f16',
    Pear:'#d1e231',
    Pearl:'#eae0c8',
    PearlAqua:'#88d8c0',
    PearlBush:'#e8e0d5',
    PearlLusta:'#fcf4dc',
    PearlMysticTurquoise:'#32c6a6',
    PearlyPurple:'#b768a2',
    Peat:'#716b56',
    Pelorous:'#3eabbf',
    Peppermint:'#e3f5e1',
    Perano:'#a9bef2',
    Perfume:'#d0bef8',
    Peridot:'#e6e200',
    PeriglacialBlue:'#e1e6d6',
    Periwinkle:'#ccccff',
    PeriwinkleGray:'#c3cde6',
    PermanentGeraniumLake:'#e12c2c',
    PersianBlue:'#1c39bb',
    PersianGreen:'#00a693',
    PersianIndigo:'#32127a',
    PersianOrange:'#d99058',
    PersianPink:'#f77fbe',
    PersianPlum:'#701c1c',
    PersianRed:'#cc3333',
    PersianRose:'#fe28a2',
    Persimmon:'#ec5800',
    Peru:'#cd853f',
    PeruTan:'#7f3a02',
    Pesto:'#7c7631',
    PetiteOrchid:'#db9690',
    Pewter:'#96a8a1',
    PewterBlue:'#8ba8b7',
    Pharlap:'#a3807b',
    PhthaloBlue:'#000f89',
    PhthaloGreen:'#123524',
    Picasso:'#fff39d',
    PickledBean:'#6e4826',
    PickledBluewood:'#314459',
    PictonBlue:'#45b1e8',
    PictorialCarmine:'#c30b4e',
    PigPink:'#fdd7e4',
    PigeonPost:'#afbdd9',
    PiggyPink:'#fddde6',
    PigmentBlue:'#333399',
    PigmentGreen:'#00a550',
    PigmentRed:'#ed1c24',
    PineCone:'#6d5e54',
    PineGlade:'#c7cd90',
    PineGreen:'#01796f',
    PineTree:'#171f04',
    Pink:'#ffc0cb',
    PinkFlamingo:'#fc74fd',
    PinkFlare:'#e1c0c8',
    PinkLace:'#ffddf4',
    PinkLady:'#fff1d8',
    PinkLavender:'#d8b2d1',
    PinkOrange:'#ff9966',
    PinkPearl:'#e7accf',
    PinkRaspberry:'#980036',
    PinkSherbet:'#f78fa7',
    PinkSwan:'#beb5b7',
    Piper:'#c96323',
    Pipi:'#fef4cc',
    Pippin:'#ffe1df',
    PirateGold:'#ba7f03',
    Pistachio:'#93c572',
    PixieGreen:'#c0d8b6',
    PixiePowder:'#391285',
    Pizazz:'#ff9000',
    Pizza:'#c99415',
    Plantation:'#27504b',
    Platinum:'#e5e4e2',
    Plum:'#8e4585',
    PlumpPurple:'#5946b2',
    Pohutukawa:'#8f021c',
    Polar:'#e5f9f6',
    PolishedPine:'#5da493',
    PoloBlue:'#8da8cc',
    Pomegranate:'#f34723',
    Pompadour:'#660045',
    Popstar:'#be4f62',
    Porcelain:'#eff2f3',
    Porsche:'#eaae69',
    PortGore:'#251f4f',
    Portafino:'#ffffb4',
    Portage:'#8b9fee',
    Portica:'#f9e663',
    PortlandOrange:'#ff5a36',
    PotPourri:'#f5e7e2',
    PottersClay:'#8c5738',
    PowderAsh:'#bcc9c2',
    PowderBlue:'#b0e0e6',
    PrairieSand:'#9a3820',
    Prelude:'#d0c0e5',
    Prim:'#f0e2ec',
    Primrose:'#edea99',
    PrincessPerfume:'#ff85cf',
    PrincetonOrange:'#f58025',
    ProcessCyan:'#00b7eb',
    ProcessMagenta:'#ff0090',
    ProvincialPink:'#fef5f1',
    PrussianBlue:'#003153',
    PsychedelicPurple:'#df00ff',
    Puce:'#cc8899',
    Pueblo:'#7d2c14',
    PuertoRico:'#3fc1aa',
    PullmanBrown:'#644117',
    PullmanGreen:'#3b331c',
    Pumice:'#c2cac4',
    Pumpkin:'#ff7518',
    PumpkinSkin:'#b1610b',
    Punch:'#dc4333',
    Punga:'#4d3d14',
    Purple:'#800080',
    PurpleHeart:'#69359c',
    PurpleMountainMajesty:'#9678b6',
    PurpleNavy:'#4e5180',
    PurplePizzazz:'#fe4eda',
    PurplePlum:'#9c51b6',
    PurpleTaupe:'#50404d',
    Purpureus:'#9a4eae',
    Putty:'#e7cd8c',
    QuarterPearlLusta:'#fffdf4',
    QuarterSpanishWhite:'#f7f2e1',
    Quartz:'#51484f',
    QueenBlue:'#436b95',
    QueenPink:'#e8ccd7',
    QuickSilver:'#a6a6a6',
    Quicksand:'#bd978e',
    QuillGray:'#d6d6d1',
    QuinacridoneMagenta:'#8e3a59',
    Quincy:'#623f2d',
    RYBBlue:'#0247fe',
    RYBGreen:'#66b032',
    RYBOrange:'#fb9902',
    RYBRed:'#fe2712',
    RYBViolet:'#8601af',
    RYBYellow:'#fefe33',
    RacingGreen:'#0c1911',
    RadicalRed:'#ff355e',
    Raffia:'#eadab8',
    Rainee:'#b9c8ac',
    RaisinBlack:'#242124',
    Rajah:'#fbab60',
    Rangitoto:'#2e3222',
    RangoonGreen:'#1c1e13',
    Raspberry:'#e30b5d',
    RaspberryPink:'#e25098',
    Raven:'#727b89',
    RawSienna:'#d68a59',
    RawUmber:'#826644',
    RazzleDazzleRose:'#ff33cc',
    Razzmatazz:'#e3256b',
    RazzmicBerry:'#8d4e85',
    RebeccaPurple:'#663399',
    Rebel:'#3c1206',
    Red:'#ff0000',
    RedBeech:'#7b3801',
    RedBerry:'#8e0000',
    RedDamask:'#da6a41',
    RedDevil:'#860111',
    RedOrange:'#ff5349',
    RedOxide:'#6e0902',
    RedPurple:'#e40078',
    RedRibbon:'#ed0a3f',
    RedRobin:'#80341f',
    RedSalsa:'#fd3a4a',
    RedStage:'#d05f04',
    RedViolet:'#c71585',
    Redwood:'#a45a52',
    Reef:'#c9ffa2',
    ReefGold:'#9f821c',
    RegalBlue:'#013f6a',
    Regalia:'#522d80',
    RegentGray:'#86949f',
    RegentStBlue:'#aad6e6',
    Remy:'#feebf3',
    RenoSand:'#a86515',
    ResolutionBlue:'#002387',
    Revolver:'#2c1632',
    Rhino:'#2e3f62',
    Rhythm:'#777696',
    RiceCake:'#fffef0',
    RiceFlower:'#eeffe2',
    RichBlack:'#004040',
    RichBrilliantLavender:'#f1a7fe',
    RichCarmine:'#d70040',
    RichElectricBlue:'#0892d0',
    RichGold:'#a85307',
    RichLavender:'#a76bcf',
    RichLilac:'#b666d2',
    RichMaroon:'#b03060',
    RifleGreen:'#444c38',
    RioGrande:'#bbd009',
    RipeLemon:'#f4d81c',
    RipePlum:'#410056',
    Riptide:'#8be6d8',
    RiverBed:'#434c59',
    RoastCoffee:'#704241',
    RobRoy:'#eac674',
    RobinEggBlue:'#00cccc',
    Rock:'#4d3833',
    RockBlue:'#9eb1cd',
    RockSpray:'#ba450c',
    RocketMetallic:'#8a7f80',
    RodeoDust:'#c9b29b',
    RollingStone:'#747d83',
    Roman:'#de6360',
    RomanCoffee:'#795d4c',
    RomanSilver:'#838996',
    Romance:'#fffefd',
    Romantic:'#ffd2b7',
    Ronchi:'#ecc54e',
    RoofTerracotta:'#a62f20',
    Rope:'#8e4d1e',
    Rose:'#ff007f',
    RoseBonbon:'#f9429e',
    RoseBud:'#fbb2a3',
    RoseBudCherry:'#800b47',
    RoseDust:'#9e5e6f',
    RoseEbony:'#674846',
    RoseFog:'#e7bcb4',
    RoseGold:'#b76e79',
    RosePink:'#ff66cc',
    RoseRed:'#c21e56',
    RoseTaupe:'#905d5d',
    RoseVale:'#ab4e52',
    RoseWhite:'#fff6f5',
    RoseofSharon:'#bf5500',
    Rosewood:'#65000b',
    RossoCorsa:'#d40000',
    RosyBrown:'#bc8f8f',
    Roti:'#c6a84b',
    Rouge:'#a23b6c',
    RoyalAirForceBlue:'#5d8aa8',
    RoyalAzure:'#0038a8',
    RoyalBlue:'#4169e1',
    RoyalFuchsia:'#ca2c92',
    RoyalHeath:'#ab3472',
    RoyalPurple:'#7851a9',
    Ruber:'#ce4676',
    RubineRed:'#d10056',
    Ruby:'#e0115f',
    RubyRed:'#9b111e',
    Ruddy:'#ff0028',
    RuddyBrown:'#bb6528',
    RuddyPink:'#e18e96',
    Rufous:'#a81c07',
    Rum:'#796989',
    RumSwizzle:'#f9f8e4',
    Russet:'#80461b',
    Russett:'#755a57',
    RussianGreen:'#679267',
    RussianViolet:'#32174d',
    Rust:'#b7410e',
    RusticRed:'#480404',
    RustyNail:'#86560a',
    RustyRed:'#da2c43',
    SAEECEAmber:'#ff7e00',
    SacramentoStateGreen:'#043927',
    Saddle:'#4c3024',
    SaddleBrown:'#8b4513',
    SafetyOrange:'#ff7800',
    SafetyYellow:'#eed202',
    Saffron:'#f4c430',
    SaffronMango:'#f9bf58',
    Sage:'#bcb88a',
    Sahara:'#b7a214',
    SaharaSand:'#f1e788',
    Sail:'#b8e0f9',
    Salem:'#097f4b',
    Salmon:'#fa8072',
    SalmonPink:'#ff91a4',
    Salomie:'#fedb8d',
    SaltBox:'#685e6e',
    Saltpan:'#f1f7f2',
    Sambuca:'#3a2010',
    SanFelix:'#0b6207',
    SanJuan:'#304b6a',
    SanMarino:'#456cac',
    SandDune:'#967117',
    Sandal:'#aa8d6f',
    Sandrift:'#ab917a',
    Sandstone:'#796d62',
    Sandstorm:'#ecd540',
    Sandwisp:'#f5e7a2',
    SandyBeach:'#ffeac8',
    SandyBrown:'#f4a460',
    Sangria:'#92000a',
    SanguineBrown:'#8d3d38',
    SantaFe:'#b16d52',
    SantasGray:'#9fa0b1',
    SapGreen:'#507d2a',
    Sapling:'#ded4a4',
    Sapphire:'#0f52ba',
    SapphireBlue:'#0067a5',
    Saratoga:'#555b10',
    SasquatchSocks:'#ff4681',
    SatinLinen:'#e6e4d4',
    SatinSheenGold:'#cba135',
    Sauvignon:'#fff5f3',
    Sazerac:'#fff4e0',
    Scampi:'#675fa6',
    Scandal:'#cffaf4',
    Scarlet:'#ff2400',
    ScarletGum:'#431560',
    Scarlett:'#950015',
    ScarpaFlow:'#585562',
    Schist:'#a9b497',
    SchoolBusYellow:'#ffd800',
    Schooner:'#8b847e',
    ScienceBlue:'#0066cc',
    Scooter:'#2ebfd4',
    Scorpion:'#695f62',
    ScotchMist:'#fffbdc',
    ScreaminGreen:'#66ff66',
    SeaBlue:'#006994',
    SeaBuckthorn:'#fba129',
    SeaGreen:'#2e8b57',
    SeaMist:'#c5dbca',
    SeaNymph:'#78a39c',
    SeaPink:'#ed989e',
    SeaSerpent:'#4bc7cf',
    Seagull:'#80ccea',
    SealBrown:'#59260b',
    Seance:'#731e8f',
    Seashell:'#fff5ee',
    Seaweed:'#1b2f11',
    Selago:'#f0eefd',
    SelectiveYellow:'#ffba00',
    Sepia:'#704214',
    SepiaBlack:'#2b0202',
    SepiaSkin:'#9e5b40',
    Serenade:'#fff4e8',
    Shadow:'#8a795d',
    ShadowBlue:'#778ba5',
    ShadowGreen:'#9ac2b8',
    ShadyLady:'#aaa5a9',
    Shakespeare:'#4eabd1',
    Shalimar:'#fbffba',
    Shampoo:'#ffcff1',
    Shamrock:'#33cc99',
    ShamrockGreen:'#009e60',
    Shark:'#25272c',
    SheenGreen:'#8fd400',
    SherpaBlue:'#004950',
    SherwoodGreen:'#02402c',
    Shilo:'#e8b9b3',
    ShimmeringBlush:'#d98695',
    ShingleFawn:'#6b4e31',
    ShinyShamrock:'#5fa778',
    ShipCove:'#788bba',
    ShipGray:'#3e3a44',
    Shiraz:'#b20931',
    Shocking:'#e292c0',
    ShockingPink:'#fc0fc0',
    ShuttleGray:'#5f6672',
    Siam:'#646a54',
    Sidecar:'#f3e7bb',
    Sienna:'#882d17',
    Silk:'#bdb1a8',
    Silver:'#c0c0c0',
    SilverChalice:'#acacac',
    SilverLakeBlue:'#5d89ba',
    SilverPink:'#c4aead',
    SilverSand:'#bfc1c2',
    SilverTree:'#66b58f',
    Sinbad:'#9fd7d3',
    Sinopia:'#cb410b',
    Siren:'#7a013a',
    Sirocco:'#718080',
    Sisal:'#d3cbba',
    SizzlingRed:'#ff3855',
    SizzlingSunrise:'#ffdb00',
    Skeptic:'#cae6da',
    Skobeloff:'#007474',
    SkyBlue:'#87ceeb',
    SkyMagenta:'#cf71af',
    SlateBlue:'#6a5acd',
    SlateGray:'#708090',
    SlimyGreen:'#299617',
    Smalt:'#003399',
    SmaltBlue:'#51808f',
    SmashedPumpkin:'#ff6d3a',
    Smitten:'#c84186',
    Smoke:'#738276',
    SmokeyTopaz:'#832a0d',
    Smoky:'#605b73',
    SmokyBlack:'#100c08',
    SmokyTopaz:'#933d41',
    Snow:'#fffafa',
    SnowDrift:'#f7faf7',
    SnowFlurry:'#e4ffd1',
    SnowyMint:'#d6ffdb',
    Snuff:'#e2d8ed',
    Soap:'#cec8ef',
    Soapstone:'#fffbf9',
    SoftAmber:'#d1c6b4',
    SoftPeach:'#f5edef',
    SolidPink:'#893843',
    Solitaire:'#fef8e2',
    Solitude:'#eaf6ff',
    SonicSilver:'#757575',
    Sorbus:'#fd7c07',
    SorrellBrown:'#ceb98f',
    SoyaBean:'#6a6051',
    SpaceCadet:'#1d2951',
    SpanishBistre:'#807532',
    SpanishBlue:'#0070b8',
    SpanishCarmine:'#d10047',
    SpanishCrimson:'#e51a4c',
    SpanishGray:'#989898',
    SpanishGreen:'#009150',
    SpanishOrange:'#e86100',
    SpanishPink:'#f7bfbe',
    SpanishRed:'#e60026',
    SpanishSkyBlue:'#00aae4',
    SpanishViolet:'#4c2882',
    SpanishViridian:'#007f5c',
    SpartanCrimson:'#9e1316',
    Spectra:'#2f5a57',
    Spice:'#6a442e',
    SpicyMix:'#8b5f4d',
    SpicyMustard:'#74640d',
    SpicyPink:'#816e71',
    Spindle:'#b6d1ea',
    SpiroDiscoBall:'#0fc0fc',
    Spray:'#79deec',
    SpringBud:'#a7fc00',
    SpringFrost:'#87ff2a',
    SpringGreen:'#00ff7f',
    SpringLeaves:'#578363',
    SpringRain:'#accbb1',
    SpringSun:'#f6ffdc',
    SpringWood:'#f8f6f1',
    Sprout:'#c1d7b0',
    SpunPearl:'#aaabb7',
    Squirrel:'#8f8176',
    StPatricksBlue:'#23297a',
    StTropaz:'#2d569b',
    Stack:'#8a8f8a',
    StarCommandBlue:'#007bb8',
    StarDust:'#9f9f9c',
    StarkWhite:'#e5d7bd',
    Starship:'#ecf245',
    SteelBlue:'#4682b4',
    SteelGray:'#262335',
    SteelPink:'#cc33cc',
    SteelTeal:'#5f8a8b',
    Stiletto:'#9c3336',
    Stonewall:'#928573',
    StormDust:'#646463',
    StormGray:'#717486',
    Stormcloud:'#4f666a',
    Stratos:'#000741',
    Straw:'#e4d96f',
    Strawberry:'#fc5a8d',
    Strikemaster:'#956387',
    Stromboli:'#325d52',
    Studio:'#714ab2',
    Submarine:'#bac7c9',
    SugarCane:'#f9fff6',
    SugarPlum:'#914e75',
    Sulu:'#c1f07c',
    SummerGreen:'#96bbab',
    Sun:'#fbac13',
    SunburntCyclops:'#ff404c',
    Sundance:'#c9b35b',
    Sundown:'#ffb1b3',
    Sunflower:'#e4d422',
    Sunglo:'#e16865',
    Sunglow:'#ffcc33',
    Sunny:'#f2f27a',
    Sunray:'#e3ab57',
    Sunset:'#fad6a5',
    SunsetOrange:'#fd5e53',
    Sunshade:'#ff9e2c',
    SuperPink:'#cf6ba9',
    Supernova:'#ffc901',
    Surf:'#bbd7c1',
    SurfCrest:'#cfe5d2',
    SurfieGreen:'#0c7a79',
    Sushi:'#87ab39',
    SuvaGray:'#888387',
    Swamp:'#001b1c',
    SwampGreen:'#acb78e',
    SwansDown:'#dcf0ea',
    SweetBrown:'#a83731',
    SweetCorn:'#fbea8c',
    SweetPink:'#fd9fa2',
    Swirl:'#d3cdc5',
    SwissCoffee:'#ddd6d5',
    Sycamore:'#908d39',
    Tabasco:'#a02712',
    Tacao:'#edb381',
    Tacha:'#d6c562',
    TahitiGold:'#e97c07',
    TahunaSands:'#eef0c8',
    TallPoppy:'#b32d29',
    Tallow:'#a8a589',
    Tamarillo:'#991613',
    Tamarind:'#341515',
    Tan:'#d2b48c',
    TanHide:'#fa9d5a',
    Tana:'#d9dcc1',
    Tangaroa:'#03163c',
    Tangelo:'#f94d00',
    Tangerine:'#f28500',
    TangerineYellow:'#ffcc00',
    Tango:'#ed7a1c',
    TangoPink:'#e4717a',
    Tapa:'#7b7874',
    Tapestry:'#b05e81',
    Tara:'#e1f6e8',
    Tarawera:'#073a50',
    TartOrange:'#fb4d46',
    Tasman:'#cfdccf',
    Taupe:'#483c32',
    TaupeGray:'#8b8589',
    TawnyPort:'#692545',
    TePapaGreen:'#1e433c',
    Tea:'#c1bab0',
    TeaGreen:'#d0f0c0',
    TeaRose:'#f4c2c2',
    Teak:'#b19461',
    Teal:'#008080',
    TealBlue:'#367588',
    TealDeer:'#99e6b3',
    TealGreen:'#00827f',
    Telemagenta:'#cf3476',
    Temptress:'#3b000b',
    Tenne:'#cd5700',
    Tequila:'#ffe6c7',
    TerraCotta:'#e2725b',
    Texas:'#f8f99c',
    TexasRose:'#ffb555',
    Thatch:'#b69d98',
    ThatchGreen:'#403d19',
    Thistle:'#d8bfd8',
    ThistleGreen:'#cccaa8',
    ThulianPink:'#de6fa1',
    Thunder:'#33292f',
    Thunderbird:'#c02b18',
    TiaMaria:'#c1440e',
    Tiara:'#c3d1d1',
    Tiber:'#063537',
    TickleMePink:'#fc89ac',
    Tidal:'#f1ffad',
    Tide:'#bfb8b0',
    TiffanyBlue:'#0abab5',
    TigersEye:'#e08d3c',
    TimberGreen:'#16322c',
    Timberwolf:'#dbd7d2',
    TitanWhite:'#f0eeff',
    TitaniumYellow:'#eee600',
    Toast:'#9a6e61',
    TobaccoBrown:'#715d47',
    Toledo:'#3a0020',
    Tolopea:'#1b0245',
    TomThumb:'#3f583b',
    Tomato:'#ff6347',
    TonysPink:'#e79f8c',
    Toolbox:'#746cc0',
    Topaz:'#ffc87c',
    ToreaBay:'#0f2d9e',
    ToryBlue:'#1450aa',
    Tosca:'#8d3f3f',
    TotemPole:'#991b07',
    TowerGray:'#a9bdbf',
    TractorRed:'#fd0e35',
    Tradewind:'#5fb3ac',
    Tranquil:'#e6ffff',
    Travertine:'#fffde8',
    TreePoppy:'#fc9c1d',
    Treehouse:'#3b2820',
    TrendyGreen:'#7c881a',
    TrendyPink:'#8c6495',
    Trinidad:'#e64e03',
    TropicalBlue:'#c3ddf9',
    TropicalRainForest:'#00755e',
    TropicalViolet:'#cda4de',
    Trout:'#4a4e5a',
    TrueBlue:'#0073cf',
    TrueV:'#8a73d6',
    Tuatara:'#363534',
    TuftBush:'#ffddcd',
    TuftsBlue:'#417dc1',
    Tulip:'#ff878d',
    TulipTree:'#eab33b',
    Tumbleweed:'#deaa88',
    Tuna:'#353542',
    Tundora:'#4a4244',
    Turbo:'#fae600',
    TurkishRose:'#b57281',
    Turmeric:'#cabb48',
    Turquoise:'#40e0d0',
    TurquoiseBlue:'#00ffef',
    TurquoiseGreen:'#a0d6b4',
    TurtleGreen:'#2a380b',
    TuscanRed:'#7c4848',
    TuscanTan:'#a67b5b',
    Tuscany:'#c09999',
    Tusk:'#eef3c3',
    Tussock:'#c5994b',
    Tutu:'#fff1f9',
    Twilight:'#e4cfde',
    TwilightBlue:'#eefdff',
    TwilightLavender:'#8a496b',
    Twine:'#c2955d',
    TyrianPurple:'#66023c',
    UABlue:'#0033aa',
    UARed:'#d9004c',
    UCLABlue:'#536895',
    UCLAGold:'#ffb300',
    UFOGreen:'#3cd070',
    UPForestGreen:'#014421',
    UPMaroon:'#7b1113',
    USAFABlue:'#004f98',
    Ube:'#8878c3',
    UltraPink:'#ff6fff',
    Ultramarine:'#3f00ff',
    UltramarineBlue:'#4166f5',
    Umber:'#635147',
    UnbleachedSilk:'#ffddca',
    UnderagePink:'#f9e6f4',
    UnitedNationsBlue:'#5b92e5',
    UniversityOfCaliforniaGold:'#b78727',
    UniversityOfTennesseeOrange:'#f77f00',
    UnmellowYellow:'#ffff66',
    UpsdellRed:'#ae2029',
    Urobilin:'#e1ad21',
    UtahCrimson:'#d3003f',
    Valencia:'#d84437',
    Valentino:'#350e42',
    Valhalla:'#2b194f',
    VanCleef:'#49170c',
    VanDykeBrown:'#664228',
    Vanilla:'#f3e5ab',
    VanillaIce:'#f38fa9',
    Varden:'#fff6df',
    VegasGold:'#c5b358',
    VenetianRed:'#c80815',
    VeniceBlue:'#055989',
    Venus:'#928590',
    Verdigris:'#43b3ae',
    VerdunGreen:'#495400',
    Vermilion:'#d9381e',
    Veronica:'#a020f0',
    VeryLightAzure:'#74bbfb',
    VeryLightBlue:'#6666ff',
    VeryLightMalachiteGreen:'#64e986',
    VeryLightTangelo:'#ffb077',
    VeryPaleOrange:'#ffdfbf',
    VeryPaleYellow:'#ffffbf',
    Vesuvius:'#b14a0b',
    Victoria:'#534491',
    VidaLoca:'#549019',
    Viking:'#64ccdb',
    VinRouge:'#983d61',
    Viola:'#cb8fa9',
    ViolentViolet:'#290c5e',
    Violet:'#7f00ff',
    VioletBlue:'#324ab2',
    VioletEggplant:'#991199',
    VioletRed:'#f75394',
    Viridian:'#40826d',
    ViridianGreen:'#009698',
    VisVis:'#ffefa1',
    VistaBlue:'#7c9ed9',
    VistaWhite:'#fcf8f7',
    VividAmber:'#cc9900',
    VividAuburn:'#922724',
    VividBurgundy:'#9f1d35',
    VividCerise:'#da1d81',
    VividCerulean:'#00aaee',
    VividCrimson:'#cc0033',
    VividGamboge:'#ff9900',
    VividLimeGreen:'#a6d608',
    VividMalachite:'#00cc33',
    VividMulberry:'#b80ce3',
    VividOrange:'#ff5f00',
    VividOrangePeel:'#ffa000',
    VividOrchid:'#cc00ff',
    VividRaspberry:'#ff006c',
    VividRed:'#f70d1a',
    VividRedTangelo:'#df6124',
    VividSkyBlue:'#00ccff',
    VividTangelo:'#f07427',
    VividTangerine:'#ffa089',
    VividVermilion:'#e56024',
    VividViolet:'#9f00ff',
    VividYellow:'#ffe302',
    Volt:'#ceff00',
    Voodoo:'#533455',
    Vulcan:'#10121d',
    Wafer:'#decbc6',
    WaikawaGray:'#5a6e9c',
    Waiouru:'#363c0d',
    Walnut:'#773f1a',
    WarmBlack:'#004242',
    Wasabi:'#788a25',
    WaterLeaf:'#a1e9de',
    Watercourse:'#056f57',
    Waterloo:'#7b7c94',
    Waterspout:'#a4f4f9',
    Wattle:'#dcd747',
    Watusi:'#ffddcf',
    WaxFlower:'#ffc0a8',
    WePeep:'#f7dbe6',
    WebChartreuse:'#7fff00',
    WebOrange:'#ffa500',
    Wedgewood:'#4e7f9e',
    WeldonBlue:'#7c98ab',
    WellRead:'#b43332',
    Wenge:'#645452',
    WestCoast:'#625119',
    WestSide:'#ff910f',
    Westar:'#dcd9d2',
    Wewak:'#f19bab',
    Wheat:'#f5deb3',
    Wheatfield:'#f3edcf',
    Whiskey:'#d59a6f',
    Whisper:'#f7f5fa',
    White:'#ffffff',
    WhiteIce:'#ddf9f1',
    WhiteLilac:'#f8f7fc',
    WhiteLinen:'#f8f0e8',
    WhitePointer:'#fef8ff',
    WhiteRock:'#eae8d4',
    WhiteSmoke:'#f5f5f5',
    WildBlueYonder:'#a2add0',
    WildOrchid:'#d470a2',
    WildRice:'#ece090',
    WildSand:'#f4f4f4',
    WildStrawberry:'#ff43a4',
    WildWatermelon:'#fc6c85',
    WildWillow:'#b9c46a',
    William:'#3a686c',
    WillowBrook:'#dfecda',
    WillowGrove:'#65745d',
    WillpowerOrange:'#fd5800',
    Windsor:'#3c0878',
    WindsorTan:'#a75502',
    Wine:'#722f37',
    WineBerry:'#591d35',
    WineDregs:'#673147',
    WinterHazel:'#d5d195',
    WinterSky:'#ff007c',
    WinterWizard:'#a0e6ff',
    WintergreenDream:'#56887d',
    WispPink:'#fef4f8',
    Wisteria:'#c9a0dc',
    Wistful:'#a4a6d3',
    WitchHaze:'#fffc99',
    WoodBark:'#261105',
    Woodland:'#4d5328',
    Woodrush:'#302a0f',
    Woodsmoke:'#0c0d0f',
    WoodyBrown:'#483131',
    X11DarkGreen:'#006400',
    X11Gray:'#bebebe',
    Xanadu:'#738678',
    YaleBlue:'#0f4d92',
    YankeesBlue:'#1c2841',
    Yellow:'#ffff00',
    YellowGreen:'#9acd32',
    YellowMetal:'#716338',
    YellowOrange:'#ffae42',
    YellowRose:'#fff000',
    YellowSea:'#fea904',
    YourPink:'#ffc3c0',
    YukonGold:'#7b6608',
    Yuma:'#cec291',
    Zaffre:'#0014a8',
    Zambezi:'#685558',
    Zanah:'#daecd6',
    Zest:'#e5841b',
    Zeus:'#292319',
    Ziggurat:'#bfdbe2',
    Zinnwaldite:'#ebc2af',
    ZinnwalditeBrown:'#2c1608',
    Zircon:'#f4f8ff',
    Zombie:'#e4d69b',
    Zomp:'#39a78e',
    Zorba:'#a59b91',
    Zuccini:'#044022',
    Zumthor:'#edf6ff',
  },
  palette: {
    primary: {
      main: '#15306C',
    },
    secondary: {
      main: '#F6C285',
    }
  },
  typography: {
    fontFamily: [
      'Fredoka One',
      'Nunito',
    ].join(','),
  },
  components:{
    MuiButton: {
      styleOverrides: {
        root: {
        fontWeight: 600,
        textTransform: "capitalize",
        fontFamily: "Fredoka One"
      },
      containedPrimary: {
        backgroundColor: "#15306C",
        fontWeight: 100,
        "letter-spacing": "1px"
      },
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
        color: "#000000",
        "letter-spacing": "1px"
      },
      }
    },
    MuiTypography:{
      styleOverrides: {
        root: {
        "letter-spacing": "1px"
      },
      }
    }
  }
});

declare module '@mui/material/styles' {
  interface CustomTheme extends Theme {
    global: {
      [key: string]: any;
    };
  }
  // allow configuration using `createTheme`
  interface CustomThemeOptions extends ThemeOptions {
    global?: {
      [key: string]: any;
    };
  }
  export function createTheme(options?: CustomThemeOptions): CustomTheme;
}

export default theme;
