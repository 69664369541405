import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import QuestionsTablepage from "./page/questionsTablepage";
import { useLocation } from "react-router-dom";

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const QuestionsTable = () => {
  const location = useLocation();

  return <>{<QuestionsTablepage />}</>;
};

export default QuestionsTable;
