import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Logout from '@mui/icons-material/Logout';
import AdbIcon from '@mui/icons-material/Adb';
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PeopleIcon from '@mui/icons-material/People';
import SupervisorAccount from '@mui/icons-material/SupervisorAccount';
import GroupsIcon from '@mui/icons-material/Groups';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import QuizIcon from '@mui/icons-material/Quiz';
import TextsmsIcon from '@mui/icons-material/Textsms';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
export const mainListItems = (
  <MainList />
)

function MainList() {
  const location = useLocation();
  const { t } = useTranslation();

  const NavLink = (props: any) => (
    <Link
        {...props} 
        style= {{
          opacity: "1" ,
          color: "transparent",
        }}
    />
  );

  return (
    <>
    <NavLink to="/admin/" style={{textDecoration: "none"}}>
      <ListItemButton style={{background: location.pathname.includes("home") || location.pathname == ("/admin/") ? "#eee" :"none"}} >
        <ListItemIcon>
            <DashboardIcon style={{color: location.pathname.includes("home") || location.pathname == ("/admin/") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Dashboard" style={{color: location.pathname.includes("home") || location.pathname == ("/admin/") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>

    <NavLink to="/admin/clientT" style={{textDecoration: "none"}} >
      <ListItemButton style={{background: location.pathname.includes("clientT") || location.pathname == ("/clientT/") ? "#eee" :"none"}}>
        <ListItemIcon>
            <PeopleIcon style={{color: location.pathname.includes("clientT") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Clients" style={{color: location.pathname.includes("clientT") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>

   
    <NavLink to="/admin/adminT" style={{textDecoration: "none"}} >
      <ListItemButton style={{background: location.pathname.includes("adminT") || location.pathname == ("/adminT/") ? "#eee" :"none"}}>
        <ListItemIcon>
            <SupervisorAccount style={{color: location.pathname.includes("aminT") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Administration" style={{color: location.pathname.includes("adminT") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>
    <NavLink to="/admin/contractorsT" style={{textDecoration: "none"}} >
      <ListItemButton style={{background: location.pathname.includes("contractorsT") || location.pathname == ("/contractorsT/") ? "#eee" :"none"}}>
        <ListItemIcon>
            <GroupsIcon style={{color: location.pathname.includes("contractorsT") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Contractors" style={{color: location.pathname.includes("contractorsT") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>
    <NavLink to="/admin/servicesT" style={{textDecoration: "none"}} >
      <ListItemButton style={{background: location.pathname.includes("servicesT") || location.pathname == ("/servicesT/") ? "#eee" :"none"}}>
        <ListItemIcon>
            <DesignServicesIcon style={{color: location.pathname.includes("servicesT") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Services" style={{color: location.pathname.includes("servicesT") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>
    <NavLink to="/admin/questionsT" style={{textDecoration: "none"}} >
      <ListItemButton style={{background: location.pathname.includes("questionsT") || location.pathname == ("/questionsT/") ? "#eee" :"none"}}>
        <ListItemIcon>
            <QuizIcon style={{color: location.pathname.includes("questionsT") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Questions" style={{color: location.pathname.includes("questionsT") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>
    <NavLink to="/admin/chatView" style={{textDecoration: "none"}} >
      <ListItemButton style={{background: location.pathname.includes("chatView") || location.pathname == ("/chatView/") ? "#eee" :"none"}}>
        <ListItemIcon>
            <TextsmsIcon style={{color: location.pathname.includes("chatView") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Chat View" style={{color: location.pathname.includes("chatView") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>
  </>
  );
};

export const secondaryListItems = (
  <SubList />
);

function SubList () {

  const location = useLocation();
  const { t } = useTranslation();

  const NavLink = (props: any) => (
    <Link
        {...props}
        style= {{
          opacity: "1" ,
                  color: "transparent",
                  marginRight: "18px",
        }}
    />
  );

  return(<>
    {/*<ListSubheader component="div" inset>
        SubList
  </ListSubheader>*/}
    <NavLink to="/admin/logout" style={{textDecoration: "none"}} >
      <ListItemButton>
        <ListItemIcon>
            <Logout style={{color: location.pathname.includes("logout") ? "#eba157" :"gray"}} />
        </ListItemIcon>
        <ListItemText primary="Logout" style={{color: location.pathname.includes("logout") ? "#eba157" :"gray"}} />
      </ListItemButton>
    </NavLink>
  </>);
}