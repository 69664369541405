import React, { useState, useContext } from "react";
import Dashboard from "./layouts/admin/dashboard/DashboardLayout";
import ExapleNewPage from "./layouts/admin/example/ExapleLayout";
import Login from "./layouts/admin/login/LoginLayout";
import Home from "./layouts/page/home/home";
import UnauthorizePage from "./layouts/admin/unauthorize/Unauthorize";
import "./App.css";
import styled from '@emotion/styled'
import { Box, useMediaQuery } from "@mui/material";
import { Provider } from 'react-redux';
import {store} from "./rx/store/store";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import jwt_decode from "jwt-decode";
import { logout } from "./rx/features/userSlice";
import AdminTable from "./layouts/admin/adminT/adminTableLayout";
import ServicesTable from "./layouts/admin/servicesT/servicesTableLayout";
import QuestionsTable from "./layouts/admin/questionsT/questionsTableLayout";
import ContractorsTable from "./layouts/admin/contractorsT/contractorsTableLayout";
import UserTable from "./layouts/admin/userT/userTableLayout";
import ChatViewLayout from "./layouts/admin/chatView/ChatViewLayout";
import BookingCLayout from "./layouts/admin/bookingCounter/BookingCLayout";
import ClientTable from "./layouts/admin/clientT/clientTableLayout";

const BoxMain = styled(Box)(({ theme }) => {
  return {
    backgroundColor: "white",
  };
});

const isTokenValid = () => {
  const storedTokenObject = localStorage.getItem("token");
  /*const decodedTokensObject =
    storedTokenObject && JSON.parse(storedTokenObject);*/
  const decoded = storedTokenObject && jwt_decode(storedTokenObject);

  return decoded && Date.now() < decoded.exp * 1000;
};

const PrivateRoute = ({children}) => {
  const authed = isTokenValid() // isauth() returns true or false based on localStorage
  
  return authed ? children : <Navigate to="/admin/login" />;
}



const Logout = () => {
  localStorage.removeItem("token");
  store.dispatch(logout());
  return <Navigate to="/admin/login" noThrow replace />;
};

const App = () => {

  return (
    <Provider store={store}>
          <SnackbarProvider>
            <BoxMain>
            <BrowserRouter>
              <Routes>
                <Route path='/*' element={<Page />} />
                <Route path='/admin/*' element={<Admin />} />
              </Routes>
            </BrowserRouter>
            </BoxMain>
          </SnackbarProvider>
    </Provider>
  );
}

const Page = () => {
  //render() {
      return (
        <Routes>
          <Route exact key="home" path="/" element={<Home />}/>
          <Route path="*"
            element={
                <UnauthorizePage />
            }
          />
        </Routes>
      );
    //}
}

const Admin = () => {
   // render() {
        return (
          <Routes>
            <Route exact key="login" path="/login" element={<Login />}/>
            <Route exact key="unauthorized" path="/unauthorized" element={<UnauthorizePage />}/>
  
            <Route exact path="/logout" element={<Logout/>}/>
  
  
            <Route  key="home" path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                  
                </PrivateRoute>
              }
            />
            <Route  key="example" path="/example"
              element={
                <PrivateRoute>
                  <ExapleNewPage />
                </PrivateRoute>
              }
            />

           <Route  key="clientT" path="/clientT"
              element={
                <PrivateRoute>
                  <ClientTable />
                </PrivateRoute>
              }
            />

            <Route  key="userT" path="/userT"
              element={
                <PrivateRoute>
                  <UserTable />
                </PrivateRoute>
              }
            />
            <Route  key="adminT" path="/adminT"
              element={
                <PrivateRoute>
                  <AdminTable/>
                </PrivateRoute>
              }
            />
            <Route  key="contractorsT" path="/contractorsT"
              element={
                <PrivateRoute>
                  <ContractorsTable/>
                </PrivateRoute>
              }
            />
            <Route  key="servicesT" path="/servicesT"
              element={
                <PrivateRoute>
                  <ServicesTable/>
                </PrivateRoute>
              }
            />
            <Route  key="questionsT" path="/questionsT"
              element={
                <PrivateRoute>
                  <QuestionsTable/>
                </PrivateRoute>
              }
            />
               <Route  key="chatView" path="/chatView"
              element={
                <PrivateRoute>
                  <ChatViewLayout/>
                </PrivateRoute>
              }
            />
             <Route  key="bookingCounter" path="/bookingCounter"
              element={
                <PrivateRoute>
                  <BookingCLayout/>
                </PrivateRoute>
              }
            />
            <Route path="*"
              element={
                  <UnauthorizePage />
              }
            />
          </Routes>

        );
      //}
}

export default App;
