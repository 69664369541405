import * as React from "react";
import { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ConstructionIcon from "@mui/icons-material/Construction";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FC } from "react";
import FormInput from "../../../../components/ui/FormInput";
import { FormProvider } from "react-hook-form";
import logo from "../../.././../assets/logo.png";

import Modalforgotpass from "../components/ModalForgotpass";
type IFormInputProps = {
  onSubmitHandler: any;
  handleSubmit: any;
  methods: any;
};

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Goodly Technologies
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

const Login: FC<IFormInputProps> = ({
  onSubmitHandler,
  handleSubmit,
  methods,
}) => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={logo}
          style={{ width: "350px", marginTop: "100px", marginBottom: "50px" }}
        />
        <Typography
          component="h1"
          variant="h5"
          sx={{ fontFamily: "Fredoka One" }}
        >
          Sign in
        </Typography>
        <FormProvider {...methods}>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmitHandler)}
            noValidate
            autoComplete="off"
            sx={{ mt: 1 }}
          >
            <FormInput name="Email" label="Email Address" type="Email" />
            <FormInput name="Password" label="Password" type="Password" />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("Login ")}
            </Button>
            <Grid item container justifyContent="center">
              <Button onClick={handleOpen}>Forgot Password</Button>

              <Modalforgotpass
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
              />
            </Grid>
          </Box>
        </FormProvider>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
};

export default Login;
