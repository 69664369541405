import React from "react";
import styled from "@emotion/styled";
import { Box, Typography, Button, Toolbar, Grid, Paper } from "@mui/material";
import DashboardNavbar from "../../../../components/ui/DashboardNavbar";
import Deposits from "../components/Deposits";
import BasicModal from "../components/ModalExample";
import AbcIcon from "@mui/icons-material/Abc";

const BoxMain = styled(Box)(({ theme }) => {
  return {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    padding: "30px",
  };
});

const Example = ({}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ display: "flex" }}>
      <DashboardNavbar name={"Example"} />
      <BoxMain component="main">
        <Toolbar />
        <Box>
          <Typography style={{ color: "black" }}>
            In this area we are going to put the content (Inside the Box)
          </Typography>
          <Grid sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "auto",
                  }}
                >
                  <Deposits
                    total={"5,000.00"}
                    iconB={
                      <AbcIcon style={{ height: "150px", width: "150px" }} />
                    }
                  />
                </Paper>
              </Grid>
            </Grid>
          </Grid>

          <Button onClick={handleOpen}>Open modal</Button>

          <BasicModal
            open={open}
            handleOpen={handleOpen}
            handleClose={handleClose}
          />
        </Box>
      </BoxMain>
    </Box>
  );
};

export default Example;
