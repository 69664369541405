import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { Admin, AdminApiResponse } from '../../models/AdminApiResponse';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

export const adminApi = createApi({
  reducerPath: 'adminApi',
  baseQuery: customFetchBase,
  tagTypes: ['admin'],
  endpoints: (builder) => ({
    getAllAdmin: builder.query<AdminApiResponse, {start: any, size: any, filters: any, globalFilter: any, sorting: any}>({
      query({start, size, filters, globalFilter, sorting}) {
        return {
          url: `/admin/list/${start}/${size}/${globalFilter}`,
        };
      },
    }),

    createAdmin: builder.mutation<{status:boolean}, Admin>({
      query(post) {
        return {
          url: `/admin/register`, method:'POST', body: post,
        };
      },
    }),

    deleteAdmin: builder.mutation<{status:boolean},string>({
      query(id) {
        return {
          url: `/admin/` + id, method:'DELETE',
        };
      },
    }),

    editAdmin: builder.mutation<{status:boolean}, {data:any,id:any}>({
      query(put) {
        return {
          url: `/admin/`+ put.id, method:'PUT', body: put.data,
        };
      },
    }),
  
  }),
});

export const {
  useGetAllAdminQuery, useCreateAdminMutation, useDeleteAdminMutation, useEditAdminMutation
} = adminApi;
