import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import { ClientApiResponse } from '../../models/ClientApiResponse'; 
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';

export const ClientListApi = createApi({
  reducerPath: 'ClientListApi',
  baseQuery: customFetchBase,
  tagTypes: ['ClientList'],
  endpoints: (builder) => ({
    getAllClient: builder.query<ClientApiResponse, {start: any, size: any, filters: any, globalFilter: any, sorting: any}>({
      query({start, size, filters, globalFilter, sorting}) {
        return {
          url: `/admin/client/list/${start}/${size}/${globalFilter}`,
        };
      },
    }),
  }),
});

export const {
  useGetAllClientQuery,
} = ClientListApi;
