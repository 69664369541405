import { Box, Container, Typography } from '@mui/material';
import { useAppSelector } from '../../../rx/store/store';

const Home = () => {
  const user = useAppSelector((state) => state.userState.user);
  
  return (
    <Container maxWidth='lg'>
      <Box
        sx={{
          backgroundColor: '#fff',
          mt: '2rem',
          height: '15rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant='h2'
          component='h1'
          sx={{ color: '#1f1e1e', fontWeight: 500 }}
        >
          In construction 🚧
        </Typography>
      </Box>
    </Container>
  );
};

export default Home;