import {
  Button
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

const ButtonBlue = styled(Button)(({ theme }) => {
  return {
    background: "blue",
    width: "250px",
    marginTop: "20px"
  };
});

type IButtonInputProps = {
  name: string;
  variant: any;
};

const BtnBlue: FC<IButtonInputProps> = ({ name, variant }) => {

  return (
    <ButtonBlue variant={variant} color="warning" >{name}</ButtonBlue>
  );
};

export default BtnBlue;
