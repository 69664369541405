import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Input,Box,
    Typography,
    Button,
    Toolbar,
    Grid,
    Paper,
    Tooltip,
    IconButton,
    DialogContent,
    Dialog,
    DialogActions,
    DialogTitle,
    Stack,
    TextField, } from '@mui/material';
    import MaterialReactTable, {
        type MRT_ColumnDef,
      } from "material-react-table";


import { Admin } from "../../models/AdminApiResponse";
import { Service } from "../../models/ServiceApiResponse";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  
};


interface CreateModalProps {
    columns:MRT_ColumnDef<Service>[];
    onClose: any; 
    onSubmit: any;
    open: boolean;
  }



export const NewAccountServiceModal  = ({
    open,
    columns,
    onClose,
    onSubmit,
  }: CreateModalProps) => {
    const [values, setValues] = useState<any>(() =>
      columns.reduce((acc, column) => {
        acc[column.accessorKey ?? ""] = "";
        return acc;
      }, {} as any)
    );
  
    const handleSubmit = () => {
      //put your validation logic here
      onSubmit(values);
      onClose();
    };

   
  
    return (
      <Dialog open={open}>
        <DialogTitle textAlign="center">Create New Service</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => e.preventDefault()}>
            <Stack
              sx={{
                width: "100%",
                minWidth: { xs: "300px", sm: "360px", md: "400px" },
                gap: "1.5rem",
              }}
            >
              {columns.map((column) => (
                <TextField
                key={column.accessorKey}
                label={column.header}
                name={column.accessorKey}
                 
                  onChange={(e) =>
                    setValues({ ...values, [e.target.name]: e.target.value })
                  }
                />
              ))}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions sx={{ p: "1.25rem" }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button color="secondary" onClick={handleSubmit} variant="contained">
            Create New Service
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  