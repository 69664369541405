import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import DashboardComponent from "./page/DashboardPage";
import { useLocation } from "react-router-dom";

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const Dashboard = () => {
  const location = useLocation();
  return <>{<DashboardComponent />}</>;
};

export default Dashboard;
