import React, { useState } from "react";
import styled from "@emotion/styled";
import { CircularProgress, useMediaQuery } from "@mui/material";
import ClientTablepage from "./page/clientTablepage";
import { useLocation } from "react-router-dom";



const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const ClientTable = () => {
  const location = useLocation();

  return <>{<ClientTablepage />}</>;
};

export default ClientTable;