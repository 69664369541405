import * as React from "react";
import Link from "@mui/material/Link";
import { FC } from "react";
import styled from "@emotion/styled";
import { Button, Typography, Box, Grid, Paper } from "@mui/material";
import BtnBlue from "../../../../components/buttons/BtnBlue";

import PeopleIcon from "@mui/icons-material/People";
import { pink, red } from "@mui/material/colors";

interface PropsStyle {
  namecard: string;
  total: string;
  iconB: any
  Bckcolor: any;
  lettersColor:any;
}

function preventDefault(event: any) {
  event.preventDefault();
}
const Cards: FC<PropsStyle> = ({ total, iconB, namecard, Bckcolor, lettersColor }) => {
  return (
    <>
      <Grid item xs={12} md={6} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "auto",
            background: Bckcolor ,
            
          
          }}
        >
          <Typography component="h2" variant="h6" style= {{color:lettersColor}} gutterBottom >
            {namecard}
          </Typography>

          <Typography component="p" variant="h5" color={lettersColor}>
            {total}
          </Typography>

          {iconB}

          <Typography color={lettersColor} sx={{ flex: 1 }} >
            on {new Date().getDate()}-{new Date().getMonth() + 1}-
            {new Date().getFullYear()} 
          </Typography>
        </Paper>
      </Grid>
    </>
  );
};

export default Cards;
